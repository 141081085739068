import { Grid, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import clsx from "clsx";
import { observer } from "mobx-react";
import React, { useCallback } from "react";
import { Answer } from "models/Answer";
import AcxMainTextField from "components/UI/AcxMainTextFieldGrid";
import theme from "Theme/AppTheme";
import useStyles from "Styles/Styles";
import { useStore } from "utils/useStore";
import Question from "models/Question";

import ConvoWorkflowStore from "../store/ConvoWorkflowStore";

interface ConvoNoteProps {
    answer?: Answer;
    question?: Question;

    conversationModuleId?: string;
    columnDirection: "column" | "column-reverse";
    isDisabled?: boolean;
    workflowLevel?: number;
}

const ConvoNote: React.FC<ConvoNoteProps> = observer((props) => {
    const styles = (theme: Theme) =>
        createStyles({
            fullSize: {
                width: "100%",
                height: "100%",
            },
            padding3: {
                paddingLeft: theme.spacing(3),
                paddingRight: theme.spacing(3),
            },
            botPadding: {
                paddingBottom: "1.25rem",
            },
        });
    const classes = useStyles(styles);
    // store only used for convo workflows, otherwise the note is handled by the answer
    const store = useStore(ConvoWorkflowStore);

    const onNoteChange = useCallback(
        (evt: any) => {
            if (!props.question) {
                const workflowInfo = store.currentWorkflowRes;
                if (!workflowInfo || !store.currentWorkflowLevel) return;
                workflowInfo.workflowLevelInfos[
                    store.currentWorkflowLevel
                ].note = evt.currentTarget.value;
                return;
            }
            // this should be updated if we want to allow notes on questions without answers in data labeling flow
            if (!props.answer) {
                const newAnswer = store.setAnswerForQuestion(
                    props.question,
                    [],
                    props.answer,
                    props.conversationModuleId,
                );
                newAnswer?.setNote?.(evt.currentTarget.value);
            } else {
                props.answer.setNote?.(evt.currentTarget.value);
            }
        },
        [props.answer, props.question, props.conversationModuleId, store],
    );

    return (
        <Grid
            container
            item
            xs={12}
            direction={props.columnDirection}
            justifyContent={"flex-start"}
            alignItems={"stretch"}
            className={clsx(classes.padding3, classes.fullSize)}
        >
            <Grid
                item
                xs={12}
                className={clsx(classes.fullSize, classes.botPadding)}
            >
                <AcxMainTextField
                    id={"question-note-field"}
                    rows={1}
                    rowsMax={3}
                    labelText={"Add A Note"}
                    multiLine
                    value={
                        !!props.question
                            ? props.answer?.note
                            : store.currentWorkflowRes?.workflowLevelInfos?.find(
                                  (i) => i.level === props.workflowLevel,
                              )?.note
                    }
                    onChange={onNoteChange}
                    disabled={props.isDisabled}
                    textItemStyle={
                        props.isDisabled
                            ? {
                                  backgroundColor: theme.palette.gray[50],
                                  paddingLeft: theme.spacing(1),
                                  paddingRight: theme.spacing(1),
                                  paddingBlock: theme.spacing(1),
                              }
                            : {
                                  paddingLeft: theme.spacing(1),
                                  paddingRight: theme.spacing(1),
                                  paddingBlock: theme.spacing(1),
                              }
                    }
                />
            </Grid>
        </Grid>
    );
});

export default ConvoNote;
