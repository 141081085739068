import React, { useMemo, useRef } from "react";
import { Box, Grid, Typography } from "@mui/material";
import theme from "Theme/AppTheme";
import AcxMainTextField from "components/UI/AcxMainTextField";
import { ConcatFieldType } from "../types";
import AcxSelectMulti from "components/UI/Select/BaseSelectComponents/AcxSelectMulti";
import useHoverAction from "../useHoverAction";
import DeleteButton from "../DeleteButton";

type ConcatFieldProps = ConcatFieldType & {
    updateConcatFields: (key: string, value: string | string[]) => void;
    sourceFieldOptions: { label: string; value: string }[];
    removeConcatField: () => void;
};

const Label = ({ children }: { children: React.ReactNode }) => (
    <Grid item xs={4} sm={4} md={4} lg={2}>
        <Typography
            sx={{
                fontWeight: 600,
                color: theme.palette.gray[500],
                fontSize: "14px",
                width: "120px",
            }}
        >
            {children}
        </Typography>
    </Grid>
);

const Field = ({ children }: { children: React.ReactNode }) => (
    <Grid
        item
        xs={8}
        sm={8}
        md={8}
        lg={10}
        sx={{
            "> div": {
                maxWidth: `calc(100% - ${theme.spacing(2.5)})`,
            },
        }}
    >
        {children}
    </Grid>
);

const ConcatField: React.FC<ConcatFieldProps> = ({
    id,
    newName,
    fields,
    separator,
    updateConcatFields,
    sourceFieldOptions,
    removeConcatField,
}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const { showAction, handlers } = useHoverAction(containerRef);
    const fieldOptions = useMemo(
        () => fields.map((field) => ({ label: field, value: field })),
        [fields],
    );

    return (
        <Box
            ref={containerRef}
            {...handlers}
            sx={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
                gap: theme.spacing(2),
                padding: theme.spacing(2, 0),
                borderRadius: 2,
                border: `1px solid ${theme.palette.gray[200]}`,
            }}
        >
            <Grid
                container
                sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: theme.spacing(0, 2),
                }}
            >
                <Label>New Field Name</Label>
                <Field>
                    <AcxMainTextField
                        placeholderText="Enter new field name, i.e. 'Combined Call Info'"
                        fullWidth
                        id={`${id}-new-field-name`}
                        value={newName}
                        onChange={(e) =>
                            updateConcatFields("newName", e.target.value)
                        }
                    />
                </Field>
            </Grid>
            <Grid
                container
                sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: theme.spacing(0, 2),
                }}
            >
                <Label>Field Selection</Label>
                <Field>
                    <AcxSelectMulti
                        fullWidth
                        id={`${id}-field-selection`}
                        options={sourceFieldOptions}
                        placeholder="Select fields to combine"
                        labelField="label"
                        valueField="value"
                        defaultValue={fieldOptions}
                        onChange={(values) => {
                            if (values) {
                                updateConcatFields(
                                    "fields",
                                    values.map((value) => value.value),
                                );
                            }
                        }}
                    />
                </Field>
            </Grid>
            <Grid
                sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: theme.spacing(0, 2),
                }}
            >
                <Label>Separator Input</Label>
                <Field>
                    <AcxMainTextField
                        fullWidth
                        placeholderText="Specify a separator"
                        id={`${id}-separator`}
                        value={separator}
                        onChange={(e) =>
                            updateConcatFields("separator", e.target.value)
                        }
                    />
                </Field>
            </Grid>
            {separator && !!fields.length && newName && (
                <Box
                    sx={{
                        borderTop: `1px solid ${theme.palette.gray[200]}`,
                        padding: theme.spacing(2),
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: 600,
                            fontSize: "16px",
                            marginBottom: theme.spacing(2),
                        }}
                    >
                        Preview
                    </Typography>
                    <Typography sx={{ fontSize: "14px" }}>
                        {fields.join(separator)}
                    </Typography>
                </Box>
            )}
            <DeleteButton
                onDelete={removeConcatField}
                showDelete={showAction}
                sx={{
                    position: "absolute",
                    top: theme.spacing(1),
                    right: theme.spacing(1),
                }}
            />
        </Box>
    );
};

export default ConcatField;
