import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import theme from "Theme/AppTheme";
import AcxMainTextField from "components/UI/AcxMainTextFieldGrid";
import Statement from "./Statement";
import { Box, Typography } from "@mui/material";

type ResultStatementProps = {
    result: string;
    id: string;
    updateResult: (value: string) => void;
    removeResult: () => void;
};

const ResultStatement: React.FC<ResultStatementProps> = observer(
    ({ result, updateResult, removeResult, id }) => {
        const [value, setValue] = useState(result);

        useEffect(() => {
            const handler = setTimeout(() => {
                updateResult(value);
            }, 300);

            return () => {
                clearTimeout(handler);
            };
            // No need to run this function when updateResult changes
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [value]);

        return (
            <Statement
                remove={removeResult}
                label="RESULT"
                sx={{ gap: theme.spacing(2), marginLeft: theme.spacing(2) }}
            >
                <Box
                    sx={{
                        background: theme.palette.gray[100],
                        width: "100%",
                        display: "flex",
                        gap: theme.spacing(2),
                        padding: theme.spacing(2),
                        borderRadius: 2,
                    }}
                >
                    <Typography
                        sx={{ alignSelf: "center", fontWeight: 600 }}
                        variant="body1"
                    >
                        Persist
                    </Typography>
                    <AcxMainTextField
                        id={`result-value-${id}`}
                        placeholderText="Enter value"
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                    />
                </Box>
            </Statement>
        );
    },
);

export default ResultStatement;
