import { Collapse, Grid, GridSize, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import clsx from "clsx";
import { observer } from "mobx-react";
import React, { FunctionComponent, useCallback } from "react";
import { Answer } from "../../../../models/Answer";
import Question from "../../../../models/Question";
import SoundClip from "../../../../models/SoundClip";
import useStyles from "../../../../Styles/Styles";
import theme from "../../../../Theme/AppTheme";
import { useStore } from "../../../../utils/useStore";
import AcxMainTextField from "../../../UI/AcxMainTextFieldGrid";
import AcxSelectMulti from "../../../UI/Select/BaseSelectComponents/AcxSelectMulti";
import { EvalStore } from "../../Stores/EvalStore";

interface OwnProps {
    answer?: Answer;
    question: Question;
    showNote?: boolean;
    showQuote?: boolean;

    noteXs?: GridSize;
    noteSm?: GridSize;
    noteMd?: GridSize;
    noteLg?: GridSize;
    noteXl?: GridSize;

    quoteXs?: GridSize;
    quoteSm?: GridSize;
    quoteMd?: GridSize;
    quoteLg?: GridSize;
    quoteXl?: GridSize;

    evaluationModuleId?: string;
    columnDirection: "column" | "column-reverse";
    isDisabled?: boolean;
}

type Props = OwnProps;

const selectCustomStyle = {
    menuPortal: (provided, state) => ({ ...provided, zIndex: 9999 }),
};

const QuoteNote: FunctionComponent<Props> = observer((props) => {
    const styles = (theme: Theme) =>
        createStyles({
            fullSize: {
                width: "100%",
                height: "100%",
            },
            padding3: {
                paddingLeft: theme.spacing(3),
                paddingRight: theme.spacing(3),
            },
            botPadding: {
                paddingBottom: "1.25rem",
            },
        });
    const classes = useStyles(styles);
    const store = useStore(EvalStore);

    // ToDo: added useMemo in update

    const setClipsForAnswer = useCallback(
        (soundClips: SoundClip[] | undefined) => {
            const tags = props.answer?.activeTags ?? [];
            const newAns = store.currentEval?.setAnswerForQuestion(
                props.question,
                tags,
                props.answer,
                props.evaluationModuleId,
            );
            newAns?.setSoundClips(soundClips);
        },
        [
            props.answer,
            props.question,
            props.evaluationModuleId,
            store.currentEval,
        ],
    );

    const onNoteChange = useCallback(
        (evt: any) => {
            if (!props.answer) {
                const newAnswer = store.currentEval?.setAnswerForQuestion(
                    props.question,
                    [],
                    props.answer,
                    props.evaluationModuleId,
                );
                newAnswer?.setNote(evt.currentTarget.value);
            } else {
                props.answer.setNote(evt.currentTarget.value);
            }
        },
        [
            props.answer,
            props.question,
            props.evaluationModuleId,
            store.currentEval,
        ],
    );

    return (
        <Grid
            container
            item
            xs={12}
            direction={props.columnDirection}
            justifyContent={"flex-start"}
            alignItems={"stretch"}
            className={clsx(classes.padding3, classes.fullSize)}
        >
            <Grid
                item
                xs={props.noteXs}
                className={clsx(classes.fullSize, classes.botPadding)}
                style={{ display: props.showNote ? "block" : "none" }}
            >
                <Collapse
                    in={props.showNote}
                    timeout={theme.transitions.duration.standard}
                >
                    <AcxMainTextField
                        id={"question-note-field"}
                        rows={3}
                        rowsMax={5}
                        labelText={"Add A Note"}
                        multiLine
                        value={props.answer?.note}
                        onChange={onNoteChange}
                        disabled={props.isDisabled}
                    />
                </Collapse>
            </Grid>
            <Grid
                item
                xs={props.noteXs}
                className={clsx(classes.fullSize, classes.botPadding)}
                style={{ display: props.showQuote ? "block" : "none" }}
            >
                <Collapse
                    in={props.showQuote}
                    timeout={theme.transitions.duration.standard}
                >
                    <AcxSelectMulti
                        options={store.currentEval?.activeSoundClips ?? []}
                        containerHeight={"auto"}
                        customStyle={selectCustomStyle}
                        menuPlacement={"auto"}
                        menuPortalTarget={document.body}
                        defaultValue={props.answer?.activeSoundClips}
                        isClearable
                        fullWidth
                        inputLabel={"Add clip(s)"}
                        onChange={setClipsForAnswer}
                        id={"QuoteSelectorForModuleAnswer" + props.question.id}
                        valueField={"segmentName"}
                        labelField={"segmentName"}
                        isDisabled={props.isDisabled}
                    />
                </Collapse>
            </Grid>
        </Grid>
    );
});

export default QuoteNote;
