import {
    ClipTag,
    EvalLicensedModule,
} from "components/Evaluation/Stores/EvalStore";
import * as _ from "lodash";
import { action, computed, makeObservable, observable } from "mobx";
import { copyBaseFields } from "../utils/BaseEntityUtils";
import { Action } from "./Actions/Action";
import { EvalReviewActionMetadata } from "./Actions/Evaluation/EvalReviewActionMetadata";
import { Answer } from "./Answer";
import BaseEntity from "./BaseEntity";
import type Classifier from "./ClassifierModel";
import type { ClassifierResult } from "./ClassifierResult";
import { ClassifierResultValidation } from "./ClassifierResultValidation";
import EvaluationModule from "./EvaluationModule";
import Interaction from "./Interaction";
import Organization from "./Organization";
import { ApplicationUser } from "./Permission/ApplicationUser";
import Question from "./Question";
import SoundClip from "./SoundClip";
import { Tag } from "./Tag";
import { WorkflowCondition } from "./Workflows/WorkflowCondition";
import {
    AssignedWorkflowInstance,
    WorkflowInstance,
} from "./Workflows/WorkflowInstance";
import { SoundClipAnswer } from "./SoundClipAnswer";
import MessageStore from "components/ManagerInteractions/Stores/MessageStore";

export enum EvaluationStatus {
    NotStarted,
    InProgress,
    Completed,
    NoValue,
    PendingReview,
    ReviewComplete,
    Disputed,
    Escalated,
    Enriching,
}

export enum EvalType {
    BottomOfTheFunnel,
    MiddleOfTheFunnel,
}

enum ConversionType {
    None,
    Minimal,
    Full,
}

export default class Evaluation extends BaseEntity {
    @observable qbAppId?: string;
    @observable evaluationQbId?: number;
    @observable organization: Organization;
    @observable organizationId: string;
    @observable interaction?: Interaction;
    @observable interactionId?: string;
    @observable soundClips?: SoundClip[];
    @observable answers: Answer[];
    @observable evaluationModules: EvaluationModule[];
    @observable submittedDate?: string;
    @observable assignedDate?: string;
    @observable workflowInstances?: WorkflowInstance[];
    @observable analystTimeSpent?: string;

    @observable classifierResultValidations: ClassifierResultValidation[] = [];

    @observable evaluationStatus: EvaluationStatus;
    @observable type: EvalType;
    @observable conversionType?: ConversionType;
    convertedBy?: string;
    analystId?: string;
    analyst?: ApplicationUser;
    agentCoachingId?: string;
    scores?: any;
    agentAcknowledged?: boolean;

    @observable canChangeDisputedAnswers?: boolean;
    @observable isEditable?: boolean;
    @observable isDisputable?: boolean;
    @observable previouslyDisputed?: boolean;
    @observable actionThread?: Action<EvalReviewActionMetadata>;
    @observable allActionThreads?: Array<Action<EvalReviewActionMetadata>>;

    constructor() {
        // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
        super();

        makeObservable(this);
    }

    @action
    setValidationForClassificationResult(
        classifierResult: ClassifierResult,
        result: "true" | "false" | undefined,
        classifierResultValidation: ClassifierResultValidation | undefined,
    ) {
        if (classifierResultValidation === undefined) {
            const newValidation = new ClassifierResultValidation(
                undefined,
                this.createdBy,
                this.modifiedBy,
                undefined,
                undefined,
                this.id,
            );

            newValidation.setResult(result);
            newValidation.setClassifier(classifierResult.classifier);

            this.updateClassifierResultValidation(
                classifierResult.classifier,
                newValidation,
            );
            classifierResultValidation = newValidation;
        } else {
            classifierResultValidation.setResult(result);
            classifierResultValidation.setClassifier(
                classifierResult.classifier,
            );

            this.updateClassifierResultValidation(
                classifierResult.classifier,
                classifierResultValidation,
            );
        }

        return classifierResultValidation;
    }

    getClassifierValidationForResult: (
        this: Evaluation,
        classifierResult: ClassifierResult,
    ) => ClassifierResultValidation | undefined = function (
        this: Evaluation,
        classifierResult: ClassifierResult,
    ) {
        const res = this.classifierResultValidations.find(
            (value) => value.classifierId === classifierResult.classifierId,
        );

        return res;
    };

    getAnswerForQuestion: (
        this: Evaluation,
        question: Question,
        evaluationModuleId: string | undefined,
    ) => Answer | undefined = function (
        this: Evaluation,
        question: Question,
        evaluationModuleId: string | undefined,
    ) {
        let ans: Answer | undefined = undefined;
        const answers = this.answers?.filter(
            (value) =>
                value.questionId === question.id &&
                (!value.evaluationModuleId ||
                    value.evaluationModuleId === evaluationModuleId),
        );

        if (answers.length > 0) {
            ans = answers[0];
        }

        ans?.setQuestion(question);
        return ans;
    };

    isModuleDisputedOrEscalated: (
        this: Evaluation,
        module: EvalLicensedModule,
    ) => boolean = function (this: Evaluation, module: EvalLicensedModule) {
        if (this.evaluationStatus === EvaluationStatus.Escalated) {
            let condition = new WorkflowCondition();
            for (const workflowInstance of this
                .workflowInstances as AssignedWorkflowInstance[]) {
                if (
                    workflowInstance.workflowCondition.licensedModuleId ===
                    module.id
                )
                    condition = workflowInstance.workflowCondition;
            }
            if (
                module.evaluationModule.moduleScore == null ||
                condition?.scoreThreshold == null
            )
                return false;
            else if (
                module.evaluationModule.moduleScore < condition.scoreThreshold
            )
                return true;
            else return false;
        } else if (this.evaluationStatus === EvaluationStatus.Disputed) {
            const answers = this.answers?.filter(
                (value) =>
                    value.isDisputed &&
                    value.question.licensedModuleId === module.id,
            );
            if (answers.length > 0) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    @action
    setAnswerForQuestion = (
        question: Question,
        tags: Tag[],
        answer: Answer | undefined,
        evaluationModuleId?: string,
        autoBindClips?: boolean,
        messageStore?: MessageStore,
    ) => {
        if (answer === undefined) {
            const newAnswer = new Answer(
                this.id,
                question.id,
                question,
                undefined,
                this.createdBy,
                this.modifiedBy,
            );
            this.updateAnswer(
                newAnswer,
                question.id,
                newAnswer.evaluationModuleId ?? evaluationModuleId,
                tags,
                autoBindClips,
                messageStore,
            );
            answer = newAnswer;
        } else {
            this.updateAnswer(
                answer,
                question.id,
                answer.evaluationModuleId ?? evaluationModuleId,
                tags,
                autoBindClips,
                messageStore,
            );
        }
        answer.setAnswerTags(tags);

        return answer;
    };

    @action
    setFillInAnswerForQuestion = (
        question: Question,
        fillInAnswer: string,
        answer: Answer | undefined,
        evaluationModuleId?: string,
    ) => {
        if (answer === undefined) {
            const newAnswer = new Answer(
                this.id,
                question.id,
                question,
                undefined,
                this.createdBy,
                this.modifiedBy,
                fillInAnswer,
            );

            this.updateAnswer(
                newAnswer,
                question.id,
                newAnswer.evaluationModuleId ?? evaluationModuleId,
            );
            answer = newAnswer;
        } else {
            answer.fillInAnswerValue = fillInAnswer;
            this.updateAnswer(
                answer,
                question.id,
                answer.evaluationModuleId ?? evaluationModuleId,
            );
        }

        return answer;
    };

    @action
    private updateClassifierResultValidation(
        classifier: Classifier,
        classifierResultValidation: ClassifierResultValidation,
    ) {
        if (classifier.id === undefined) {
            return;
        }

        const existingIndex = this.classifierResultValidations.findIndex(
            (value) => value.classifierId === classifier.id,
        );

        if (existingIndex === -1) {
            this.classifierResultValidations.push(classifierResultValidation);
        } else {
            this.classifierResultValidations.splice(
                existingIndex,
                1,
                classifierResultValidation,
            );
        }
    }

    @action
    updateAnswer(
        answer: Answer,
        questionId: string | undefined,
        evaluationModuleId: string | undefined,
        newTags: Tag[] = [],
        autoBindClips?: boolean,
        messageStore?: MessageStore,
    ) {
        if (questionId === undefined) {
            return;
        }

        const existingIndex = this.answers.findIndex(
            (value) =>
                value.questionId === questionId &&
                (!value.evaluationModuleId ||
                    value.evaluationModuleId === evaluationModuleId),
        );

        answer.evaluationModuleId = evaluationModuleId;

        if (existingIndex === -1) {
            this.answers = [...this.answers, answer];
        } else {
            this.answers.splice(existingIndex, 1, answer);
            this.answers = [...this.answers];
        }

        if (!autoBindClips || !newTags.length) return;

        const soundClipAnswersOnQuestion: SoundClipAnswer[] =
            answer.soundClipAnswers ?? [];

        const answerTypeName = answer.question.answerType.answerTypeName;

        const isTagResponseTypeAndNotSingle =
            (answerTypeName === "Tag Response" ||
                answerTypeName === "Scored Tag Response") &&
            answer.question.answerType.variation !== "Single";

        if (isTagResponseTypeAndNotSingle) return;

        soundClipAnswersOnQuestion.forEach((soundClipAnswer) => {
            const soundClip = soundClipAnswer.soundClip;
            if (!soundClip) return;

            const existingTags: ClipTag[] = soundClip.clipTags ?? [];

            if (existingTags.length !== 0) {
                const isTagInNewTags = (tag: Tag) => {
                    return newTags.some((newTag) => newTag.id === tag.id);
                };
                const removedOrChangedTag = existingTags.find(
                    (tag) =>
                        tag.questionId === questionId && !isTagInNewTags(tag),
                );

                const addNewClipTags = () => {
                    const hasTagsForQuestion = existingTags.some(
                        (tag) => tag.questionId === questionId,
                    );

                    if (hasTagsForQuestion) {
                        const tagIndex = existingTags.findIndex(
                            (tag) => tag.questionId === questionId,
                        );
                        existingTags.splice(tagIndex, 1);
                    }
                    soundClip.setClipTags([...existingTags, ...newTags] ?? []);
                };

                const setRemovedOrChangedClipTags = () => {
                    const tagsMinusRemovedChangedTag = existingTags.filter(
                        (tag) => tag.id !== removedOrChangedTag?.id,
                    );
                    soundClip.setClipTags([...tagsMinusRemovedChangedTag]);
                };

                if (removedOrChangedTag) {
                    const sameQuestionChanged = existingTags.some(
                        (tag) => tag.questionId === questionId,
                    );

                    if (!sameQuestionChanged) {
                        setRemovedOrChangedClipTags();
                    } else {
                        addNewClipTags();
                    }
                } else {
                    addNewClipTags();
                }
            } else {
                soundClip.setClipTags([...newTags]);
            }
        });
        if (soundClipAnswersOnQuestion.length) {
            messageStore?.logMessage(
                "Clip(s) attached to this question have been updated with the selected answer.",
                "success",
            );
        }
    }

    @action
    public updateHeaderAnswers(hierarchyQuestions: Question[]) {
        for (const hierarchyQuestion of hierarchyQuestions) {
            const childAnswers = this.answers.filter(
                (value) => value.question?.parentId === hierarchyQuestion.id,
            );

            const childScores = childAnswers
                ?.filter((value) => value.weightedScore != null)
                .map((value) => value.weightedScore!);

            const hierarchyQuestionAnswer = this.setAnswerForQuestion(
                hierarchyQuestion,
                [],
                this.answers.find(
                    (value) => value.questionId === hierarchyQuestion.id,
                ),
            );

            if (childScores?.length) {
                hierarchyQuestionAnswer.weightedScore =
                    _.sum(childScores) / childScores.length;
            } else {
                hierarchyQuestionAnswer.weightedScore = null;
            }
        }
    }

    @action
    deleteClip = (soundClip?: SoundClip) => {
        if (soundClip) {
            soundClip.isActive = false;
            if (!soundClip.id) {
                const remove =
                    this.soundClips?.findIndex(
                        (value) =>
                            value.startTime === soundClip.startTime &&
                            value.endTime === soundClip.endTime,
                    ) ?? -1;
                if (remove > -1) {
                    this.soundClips?.splice(remove, 1);
                }
            }
        }
    };

    @computed
    get activeSoundClips(): SoundClip[] | undefined {
        const evalAnswerids = this.answers.map((a) => a.id);
        return this.soundClips
            ?.filter((s) => s.isActive)
            .filter((sc) => {
                return sc.soundClipAnswers?.every((sca) =>
                    evalAnswerids?.includes(sca.answerId),
                );
            })
            .sort((a, b) => a.startTime - b.startTime)
            .slice();
    }

    get isInReviewState() {
        return (
            this.evaluationStatus === EvaluationStatus.PendingReview ||
            this.evaluationStatus === EvaluationStatus.ReviewComplete
        );
    }

    @computed
    get isComplete() {
        return Boolean(this.submittedDate);
    }

    @computed
    get disputedAnswers() {
        return this.answers?.filter((a) => a.isDisputed);
    }

    static fromJson(evalJson: Evaluation) {
        const evalCls = new Evaluation();
        copyBaseFields(evalJson, evalCls);
        evalCls.qbAppId = evalJson.qbAppId;
        evalCls.evaluationQbId = evalJson.evaluationQbId;
        evalCls.organization = evalJson.organization;
        evalCls.organizationId = evalJson.organizationId;
        evalCls.interactionId = evalJson.interactionId;
        evalCls.submittedDate = evalJson.submittedDate;
        evalCls.analystId = evalJson.analystId;
        evalCls.evaluationModules = evalJson.evaluationModules;
        evalCls.evaluationStatus = evalJson.evaluationStatus;
        evalCls.type = evalJson.type;
        evalCls.conversionType = evalJson.conversionType;
        evalCls.convertedBy = evalJson.convertedBy;
        evalCls.assignedDate = evalJson.assignedDate;
        evalCls.analyst = evalJson.analyst;
        evalCls.agentCoachingId = evalJson.agentCoachingId;
        evalCls.workflowInstances = evalJson.workflowInstances;
        evalCls.analystTimeSpent = evalJson.analystTimeSpent;

        evalCls.canChangeDisputedAnswers = evalJson.canChangeDisputedAnswers;
        evalCls.isEditable = evalJson.isEditable;
        evalCls.isDisputable = evalJson.isDisputable;
        evalCls.previouslyDisputed = evalJson.previouslyDisputed;
        evalCls.agentAcknowledged = evalJson.agentAcknowledged;

        evalCls.actionThread = evalJson.actionThread
            ? Action.fromJson<EvalReviewActionMetadata>(evalJson.actionThread)
            : undefined;

        evalCls.allActionThreads = evalJson.allActionThreads
            ?.map((value) => Action.fromJson<EvalReviewActionMetadata>(value))
            ?.sort((a, b) =>
                (a.createdOn ?? "") < (b.createdOn ?? "") ? 1 : -1,
            );

        evalCls.classifierResultValidations =
            evalJson.classifierResultValidations?.map((value) =>
                ClassifierResultValidation.fromJson(value),
            ) ?? [];

        evalCls.interaction = Interaction.fromJson(evalJson.interaction);

        evalCls.soundClips =
            evalJson.soundClips
                ?.map((value) => SoundClip.fromJson(value))
                ?.sort((a, b) => (a.startTime ?? 0) - (b.startTime ?? 0)) ?? [];

        evalCls.answers =
            evalJson.answers?.map((value) => Answer.fromJson(value)) ?? [];

        return evalCls;
    }
}

export function EvaluationStatusEnumStrConverter(status: EvaluationStatus) {
    if (typeof status === "string") {
        status = parseInt(status, 10);
    }
    switch (status) {
        case EvaluationStatus.Completed:
            return "Completed";
        case EvaluationStatus.InProgress:
            return "In Draft";
        case EvaluationStatus.NotStarted:
            return "Not Started";
        case EvaluationStatus.NoValue:
            return "No Value";
        case EvaluationStatus.PendingReview:
            return "Pending Review";
        case EvaluationStatus.ReviewComplete:
            return "Review Complete";
        case EvaluationStatus.Disputed:
            return "Disputed";
        case EvaluationStatus.Escalated:
            return "Escalated";
        case EvaluationStatus.Enriching:
            return "Enriching";
        default:
            return "";
    }
}
