// import { toJS } from "mobx";
import { observer, Observer } from "mobx-react";
import { Answer } from "models/Answer";
import { AnswerTypeNames } from "models/AnswerType";
import Question, {
    QuestionConditionalGroupType,
    QuestionDependencyCondition,
    QuestionDependencyQuestionAnswerOperator,
    QuestionDependencyType,
} from "models/Question";
import React from "react";
import theme, { ThemeColors } from "Theme/AppTheme";
import hexToRGB from "utils/hexToRGB";
import ConvoWorkflowStore from "../store/ConvoWorkflowStore";
import { useStore } from "utils/useStore";
import { Divider, Grid } from "@mui/material";
import AcxButton from "components/UI/AcxButton";
import { WorkflowStatus } from "models/Workflows/WorkflowInstance";
import ConvoNote from "./ConvoNote";
import ConvoScaledResponseAnswer from "./ConvoScaledResponseAnswer";
import ConvoStarRatingAnswer from "./ConvoStarRatingAnswer";
import ConvoTOFIResponseAnswer from "./ConvoTOFIResponseAnswer";
import { endDialogTitle } from "../ConvoWorkflowContainer";
import ConversationLabelingStore from "../store/ConversationLabelingStore";
import ConversationsStore, {
    SidePanelType,
} from "components/Conversations/Stores/ConversationsStore";
import LicensedModule from "models/LicensedModule";
import { ConvoModuleUIModel } from "./ConvoModuleUIModel";

interface ConvoQuestion extends Question {
    conversationModuleId: string;
}

interface OwnProps {
    convoModuleId?: string;
    licensedModuleId?: string;
    isEditable?: boolean;
    workflowLevel?: number;
    isDataLabeling?: boolean;
    module?: LicensedModule;
    uiModel?: ConvoModuleUIModel;
    answers: Answer[];
}

type Props = OwnProps;

function isConditionMet(
    condition: QuestionDependencyCondition,
    answers: Answer[],
    conversationModuleId: string,
): boolean {
    switch (condition.type) {
        case QuestionDependencyType.QuestionAnswer:
            const value = condition.value;
            const answer = answers.find(
                (a) =>
                    a.questionId === condition.dependsOnQuestionId &&
                    a.conversationModuleId === conversationModuleId,
            );

            if (!answer) {
                return false;
            }

            switch (condition.op) {
                case QuestionDependencyQuestionAnswerOperator.Contains:
                    return answer.activeAnswerTags.some(
                        (t) => t.tag?.value === value,
                    );
                case QuestionDependencyQuestionAnswerOperator.IsAnswered:
                    return !!answer.activeAnswerTags.length;
            }

            break;
        case QuestionDependencyType.ConditionalGroup:
            switch (condition.conditionalGroupType) {
                case QuestionConditionalGroupType.And:
                    return condition.conditions.every((c) =>
                        isConditionMet(c, answers, conversationModuleId),
                    );
                case QuestionConditionalGroupType.Or:
                    return condition.conditions.some((c) =>
                        isConditionMet(c, answers, conversationModuleId),
                    );
            }
    }
}

function shouldRender(answers: Answer[] | undefined, question: ConvoQuestion) {
    if (!question.dependencies) {
        return true;
    }
    if (!question.dependencies.conditions) {
        return true;
    }
    if (!question.dependencies.conditions.length) {
        return true;
    }
    if (!answers) {
        return false;
    }

    return question.dependencies.conditions.every((d) =>
        isConditionMet(d, answers, question.conversationModuleId),
    );
}

const ConvoQuestionAnswerList = observer((props: Props) => {
    const store = useStore(ConvoWorkflowStore);
    const conversationsStore = useStore(ConversationsStore);
    const dataLabelStore = useStore(ConversationLabelingStore);

    const questionsWithDependencies = props.module?.questions.filter(
        (r) => r.dependencies?.conditions.length,
    );

    const sortedQuestions = (props.module?.sortedQuestion ?? []).filter((q) => {
        var convoQuestion = q as ConvoQuestion;
        convoQuestion.conversationModuleId = props.convoModuleId as string;

        return shouldRender(props.answers, convoQuestion);
    });

    // TODO: this section should be refactored out of this component at some point
    let isMissingRequiredAnswer =
        store.isMissingRequiredAnswer(sortedQuestions);

    if (props.isDataLabeling) {
        isMissingRequiredAnswer =
            dataLabelStore.isMissingRequiredAnswer(sortedQuestions);
    }

    // Need to do this in case the user has updated soundClip name or notes during data labelling
    const syncSoundClipNameAndNoteWithStore = () => {
        const soundClipAnswers = dataLabelStore.answers.flatMap(
            (a) => a.soundClipAnswers,
        );
        soundClipAnswers.forEach((sca) => {
            const scFromStore = conversationsStore.activeSoundClips?.find(
                (sc) => sc.id === sca.soundClip?.id,
            );
            if (sca.soundClip && scFromStore) {
                if (sca.soundClip.segmentName !== scFromStore.segmentName) {
                    sca.soundClip.segmentName = scFromStore.segmentName;
                }
                if (sca.soundClip.note !== scFromStore.note) {
                    sca.soundClip.note = scFromStore.note;
                }
            }
        });
    };

    // If a user has de-selected an answer, need to clear notes and clips before saving
    const removeNotesAndClipsFromUnanswered = () => {
        const unAnswered = dataLabelStore.answers.filter(
            (a) => a.isAnswered === false,
        );
        unAnswered.forEach((a) => {
            a.note = "";
            a.soundClipAnswers = [];
        });
    };

    const workflowActionButtons = (
        <>
            {store.currentWorkflowStatus !== WorkflowStatus.Closed &&
                props.isEditable && (
                    <Grid item>
                        <AcxButton
                            onClick={() => {
                                store.dialogStore.setTitle(endDialogTitle);
                                store.dialogStore.setSubTitle(
                                    "Are you sure you want to end this workflow?",
                                );
                                store.dialogStore.isOpen = true;
                            }}
                            loading={store.anyTaskLoading}
                            color={store.isLastLevel ? "primary" : "error"}
                            disabled={isMissingRequiredAnswer}
                        >
                            {store.isLastLevel ? "Complete" : "End Workflow"}
                        </AcxButton>
                    </Grid>
                )}
            {(store.workflowLevelNames?.length ?? 0) > 1 &&
                !store.isLastLevel &&
                props.isEditable && (
                    <Grid item>
                        <AcxButton
                            onClick={() => {
                                store.dialogStore.setTitle(
                                    "Confirm Advance Workflow",
                                );
                                store.dialogStore.setSubTitle(
                                    "Are you sure you want to advance this workflow to the next level?",
                                );
                                store.dialogStore.isOpen = true;
                            }}
                            disabled={isMissingRequiredAnswer}
                        >
                            Advance
                        </AcxButton>
                    </Grid>
                )}
        </>
    );

    const dataLabelingActionButtons = (
        <Grid
            item
            container
            xs={12}
            justifyContent={"flex-end"}
            margin={theme.spacing(1)}
            spacing={1}
        >
            <Grid item>
                <AcxButton
                    onClick={() => {
                        conversationsStore.setSidePanel(SidePanelType.Hidden);
                    }}
                    color={"error"}
                >
                    Cancel
                </AcxButton>
            </Grid>
            <Grid item>
                <AcxButton
                    onClick={() => {
                        syncSoundClipNameAndNoteWithStore();
                        removeNotesAndClipsFromUnanswered();
                        if (
                            !!dataLabelStore.convoModuleInfo.find(
                                (cmi) =>
                                    cmi.conversationModuleId ===
                                    dataLabelStore.selectedConversationModuleId,
                            )
                        ) {
                            dataLabelStore.updateConversationModule();
                        } else {
                            dataLabelStore.createConversationModule();
                        }
                        conversationsStore.setSidePanel(SidePanelType.Hidden);
                    }}
                    disabled={isMissingRequiredAnswer}
                >
                    Save
                </AcxButton>
            </Grid>
        </Grid>
    );

    // end section

    const renderQuestion = (questionValue: Question): React.ReactNode => {
        let questionComponent: React.ReactNode = <></>;

        if (questionValue.isActive) {
            const leftBorder =
                questionValue.parentId &&
                questionsWithDependencies?.includes(questionValue)
                    ? hexToRGB(ThemeColors.purple, 1)
                    : questionsWithDependencies?.includes(questionValue)
                    ? hexToRGB(ThemeColors.red, 0.75)
                    : questionValue.parentId
                    ? hexToRGB(ThemeColors.blue, 0.5)
                    : undefined;

            if (
                questionValue.answerType.answerTypeName ===
                    AnswerTypeNames.ScaledResponse ||
                questionValue.answerType.answerTypeName ===
                    AnswerTypeNames.ScaledBooleanResponse ||
                questionValue.answerType.answerTypeName ===
                    AnswerTypeNames.QuestionGrouping
            ) {
                questionComponent = (
                    <ConvoScaledResponseAnswer
                        moduleUIModel={props.uiModel}
                        question={questionValue}
                        leftBorderColor={leftBorder}
                        key={questionValue.id}
                        isDisabled={!props.isEditable}
                        getAnswerForQuestion={
                            props.isDataLabeling
                                ? dataLabelStore.getAnswerForQuestion
                                : store.getAnswerForQuestion
                        }
                        setAnswerForQuestion={
                            props.isDataLabeling
                                ? dataLabelStore.setAnswerForQuestion
                                : store.setAnswerForQuestion
                        }
                        showClips={props.isDataLabeling}
                    />
                );
            } else if (
                questionValue.answerType.answerTypeName ===
                    AnswerTypeNames.TagResponse ||
                questionValue.answerType.answerTypeName ===
                    AnswerTypeNames.ScoredTagResponse ||
                questionValue.answerType.answerTypeName ===
                    AnswerTypeNames.TextResponse ||
                questionValue.answerType.answerTypeName ===
                    AnswerTypeNames.DateResponse
            ) {
                questionComponent = (
                    <ConvoTOFIResponseAnswer
                        key={questionValue.id}
                        leftBorderColor={leftBorder}
                        moduleUIModel={props.uiModel}
                        question={questionValue}
                        isDisabled={!props.isEditable}
                        setAnswerForQuestion={
                            props.isDataLabeling
                                ? dataLabelStore.setAnswerForQuestion
                                : store.setAnswerForQuestion
                        }
                        getAnswerForQuestion={
                            props.isDataLabeling
                                ? dataLabelStore.getAnswerForQuestion
                                : store.getAnswerForQuestion
                        }
                        setFillInAnswerForQuestion={
                            props.isDataLabeling
                                ? dataLabelStore.setFillInAnswerForQuestion
                                : store.setFillInAnswerForQuestion
                        }
                        showClips={props.isDataLabeling}
                    />
                );
            } else if (
                questionValue.answerType.answerTypeName ===
                AnswerTypeNames.StarRating
            ) {
                questionComponent = (
                    <ConvoStarRatingAnswer
                        key={questionValue.id}
                        moduleUIModel={props.uiModel}
                        question={questionValue}
                        leftBorderColor={leftBorder}
                        isDisabled={!props.isEditable}
                        getAnswerForQuestion={
                            props.isDataLabeling
                                ? dataLabelStore.getAnswerForQuestion
                                : store.getAnswerForQuestion
                        }
                        setAnswerForQuestion={
                            props.isDataLabeling
                                ? dataLabelStore.setAnswerForQuestion
                                : store.setAnswerForQuestion
                        }
                    />
                );
            }
            return (
                <>
                    <Divider />
                    {questionComponent}
                </>
            );
        } else {
            return "";
        }
    };

    return (
        <Observer>
            {() => (
                <>
                    {!!props.convoModuleId ? (
                        <>{sortedQuestions?.map(renderQuestion)}</>
                    ) : (
                        <ConvoNote
                            columnDirection={"column"}
                            workflowLevel={props.workflowLevel}
                            isDisabled={!props.isEditable}
                        />
                    )}

                    <Grid
                        container
                        item
                        xs={12}
                        justifyContent={"flex-end"}
                        spacing={1}
                    >
                        {props.isDataLabeling
                            ? dataLabelingActionButtons
                            : workflowActionButtons}
                    </Grid>
                </>
            )}
        </Observer>
    );
});

export default ConvoQuestionAnswerList;
