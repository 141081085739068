import React from "react";
import { v4 as uuidv4 } from "uuid";
import { observer } from "mobx-react";

import StatementHandler from "./StatementHandler";
import { ConditionRule, StatementType } from "../../types";

type StatementContainerProps = {
    statement: StatementType;
    ruleIndex: number;
    conditionRules: ConditionRule[];
    setConditionRules: (conditionRules: ConditionRule[]) => void;
    fieldsOptions: { label: string; value: string }[];
    isElseStatement?: boolean;
};

const StatementContainer: React.FC<StatementContainerProps> = observer(
    ({
        statement,
        ruleIndex,
        conditionRules,
        setConditionRules,
        fieldsOptions,
        isElseStatement,
    }) => {
        const updateRule = (runUpdate: Function) => {
            setConditionRules(
                conditionRules.map((rule, i) => {
                    if (ruleIndex === i) {
                        const result = runUpdate(
                            isElseStatement ? rule.else : rule.then,
                        );
                        return {
                            ...rule,
                            ...(isElseStatement
                                ? {
                                      else: {
                                          ...rule.else,
                                          ...result,
                                      },
                                  }
                                : {
                                      then: {
                                          ...rule.then,
                                          ...result,
                                      },
                                  }),
                        };
                    }
                    return rule;
                }),
            );
        };

        const addThenConditionIndicator = () => {
            const updater = (statement: StatementType) => {
                return {
                    conditionIndicators: [
                        ...(statement.conditionIndicators || []),
                        {
                            id: uuidv4(),
                            compareType: 0,
                            valuePath: "",
                            value: "",
                            isRequired: false,
                        },
                    ],
                };
            };

            updateRule(updater);
        };

        const removeThenIndicator = (id: string) => {
            const updater = (statement: StatementType) => {
                return {
                    conditionIndicators: statement.conditionIndicators?.filter(
                        (indicator) => indicator.id !== id,
                    ),
                };
            };

            updateRule(updater);
        };

        const updateThenIndicator = (
            indicatorIndex: number,
            key: string,
            value: string | number,
        ) => {
            const updater = (statement: StatementType) => {
                return {
                    conditionIndicators: statement.conditionIndicators?.map(
                        (indicator, j) => {
                            if (indicatorIndex === j) {
                                return {
                                    ...indicator,
                                    [key]: value,
                                };
                            }
                            return indicator;
                        },
                    ),
                };
            };

            updateRule(updater);
        };

        const updateResult = (value?: string) => {
            const updater = () => {
                return {
                    result: value,
                };
            };
            updateRule(updater);
        };

        const removeResult = () => {
            updateResult(undefined);
        };

        const addResult = () => {
            updateResult("");
        };

        const updateElseResult = (value?: string) => {
            const updater = (statement: StatementType) => {
                return {
                    else: {
                        ...statement.else,
                        id: statement.else?.id || uuidv4(),
                        result: value,
                    },
                };
            };

            updateRule(updater);
        };

        const addElse = () => {
            updateElseResult("");
        };

        const removeElseResult = () => {
            updateElseResult(undefined);
        };

        const addChildStatement = () => {
            const newChildStatement = {
                id: uuidv4(),
                conditionIndicators: [
                    {
                        id: uuidv4(),
                        compareType: 0,
                        valuePath: "",
                        value: "",
                        isRequired: false,
                    },
                ],
            };

            const updater = (statement: StatementType) => {
                return {
                    else: {
                        ...statement.else,
                        id: statement.else?.id || uuidv4(),
                        childStatements: [
                            ...(statement.else?.childStatements || []),
                            newChildStatement,
                        ],
                    },
                };
            };

            updateRule(updater);
        };

        const removeSelf = () => {
            setConditionRules(
                conditionRules.map((rule, i) => {
                    if (i === ruleIndex) {
                        return {
                            ...rule,
                            else: undefined,
                        };
                    }
                    return rule;
                }),
            );
        };

        return (
            <StatementHandler
                removeSelf={isElseStatement ? removeSelf : undefined}
                isElseStatement={isElseStatement}
                statement={statement}
                fieldsOptions={fieldsOptions}
                elseStatement={statement.else}
                addThenConditionIndicator={addThenConditionIndicator}
                removeThenIndicator={removeThenIndicator}
                updateThenIndicator={updateThenIndicator}
                addResult={addResult}
                removeResult={removeResult}
                updateResult={updateResult}
                addElse={addElse}
                removeElseResult={removeElseResult}
                updateElseResult={updateElseResult}
                addChildStatement={addChildStatement}
            />
        );
    },
);

export default StatementContainer;
