import { Box, Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React, { useMemo, useRef } from "react";
import theme from "Theme/AppTheme";
import AcxButton from "components/UI/AcxButton";
import { v4 as uuidv4 } from "uuid";
import { OrganizationDataSourcesStore } from "../../OrganizationDataSourcesStore";
import { observer } from "mobx-react";
import DataSourceContainer from "../../DataSourceContainer";
import StatementContainer from "./StatementContainer";
import useHoverAction from "../../useHoverAction";
import NestedStatementContainer from "./NestedStatementContainer";
import IfStatement from "./IFStatement";
import ConditionHeader from "./ConditionHeader";
import DeleteButton from "../../DeleteButton";

type ConditionalLogicProps = {
    store: OrganizationDataSourcesStore;
};

const ConditionalLogic: React.FC<ConditionalLogicProps> = observer(
    ({ store }) => {
        const {
            sourceFields,
            conditionRules,
            setConditionRules,
            targetFields,
        } = store;
        const containerRef = useRef<HTMLDivElement>(null);
        const { showAction, handlers } = useHoverAction(containerRef);

        const addConditionIndicator = (index: number) => {
            setConditionRules(
                conditionRules.map((rule, i) => {
                    if (index === i) {
                        return {
                            ...rule,
                            conditionIndicators: [
                                ...(rule.conditionIndicators || []),
                                {
                                    id: uuidv4(),
                                    compareType: 0,
                                    valuePath: "",
                                    value: "",
                                    isRequired: false,
                                },
                            ],
                        };
                    }
                    return rule;
                }),
            );
        };

        const removeConditionIndicator = (
            ruleIndex: number,
            indicatorId: string,
        ) => {
            setConditionRules(
                conditionRules.map((rule, i) => {
                    if (ruleIndex === i) {
                        return {
                            ...rule,
                            conditionIndicators:
                                rule.conditionIndicators.filter(
                                    (indicator) => indicator.id !== indicatorId,
                                ),
                        };
                    }
                    return rule;
                }),
            );
        };

        const updateIndicator = (
            ruleIndex: number,
            indicatorIndex: number,
            key: string,
            value: string | number,
        ) => {
            setConditionRules(
                conditionRules.map((rule, i) => {
                    if (ruleIndex === i) {
                        return {
                            ...rule,
                            conditionIndicators: rule.conditionIndicators.map(
                                (indicator, j) => {
                                    if (indicatorIndex === j) {
                                        return {
                                            ...indicator,
                                            [key]: value,
                                        };
                                    }
                                    return indicator;
                                },
                            ),
                        };
                    }
                    return rule;
                }),
            );
        };

        const addConditionRule = () => {
            setConditionRules([
                ...conditionRules,
                {
                    id: uuidv4(),
                    targetMetaDataField: 0,
                    name: `Condition ${conditionRules.length + 1}`,
                    conditionIndicators: [
                        {
                            id: uuidv4(),
                            compareType: 0,
                            valuePath: "",
                            value: "",
                            isRequired: false,
                        },
                    ],
                    then: {
                        id: uuidv4(),
                        conditionIndicators: [],
                    },
                },
            ]);
        };

        const removeConditionRule = (id: string) => {
            setConditionRules(conditionRules.filter((rule) => rule.id !== id));
        };

        const updateTargetField = (ruleId: string, value: number) => {
            setConditionRules(
                conditionRules.map((rule) => {
                    if (ruleId === rule.id) {
                        return {
                            ...rule,
                            targetMetaDataField: value,
                        };
                    }
                    return rule;
                }),
            );
        };

        const updateConditionName = (ruleId: string, value: string) => {
            setConditionRules(
                conditionRules.map((rule) => {
                    if (ruleId === rule.id) {
                        return {
                            ...rule,
                            name: value,
                        };
                    }
                    return rule;
                }),
            );
        };

        const addElseStatement = (ruleId: string) => {
            setConditionRules(
                conditionRules.map((rule) => {
                    if (ruleId === rule.id) {
                        return {
                            ...rule,
                            else: {
                                id: uuidv4(),
                                conditionIndicators: [
                                    {
                                        id: uuidv4(),
                                        compareType: 0,
                                        valuePath: "",
                                        value: "",
                                        isRequired: false,
                                    },
                                ],
                            },
                        };
                    }
                    return rule;
                }),
            );
        };

        const fieldOptions = useMemo(
            () =>
                sourceFields
                    .map((f) => ({
                        label: f.alias,
                        value: f.path,
                    }))
                    .filter((f) => f.label),
            [sourceFields],
        );

        return (
            <DataSourceContainer
                heading="Conditional Logic"
                subHeading="Define conditions to control when data should be included in your mappings using flexible AND/OR rules"
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: theme.spacing(2),
                    }}
                >
                    {conditionRules.map(
                        (
                            {
                                conditionIndicators,
                                name,
                                then,
                                else: elseStatement,
                                id,
                                targetMetaDataField,
                            },
                            ruleIndex,
                        ) => {
                            return (
                                <Box
                                    sx={{
                                        position: "relative",
                                        border: `1px solid ${theme.palette.gray[200]}`,
                                        borderRadius: 2,
                                    }}
                                    key={id}
                                    ref={containerRef}
                                    {...handlers}
                                >
                                    <Grid
                                        container
                                        sx={{
                                            gap: theme.spacing(2),
                                        }}
                                    >
                                        <ConditionHeader
                                            name={name}
                                            updateConditionName={
                                                updateConditionName
                                            }
                                            updateTargetField={
                                                updateTargetField
                                            }
                                            targetMetaDataField={
                                                targetMetaDataField
                                            }
                                            targetFields={targetFields}
                                            ruleId={id}
                                        />
                                        <Grid
                                            item
                                            sx={{
                                                padding: theme.spacing(1),
                                                color: theme.palette.primary
                                                    .dark,
                                                background:
                                                    theme.palette.primary.light,
                                                margin: theme.spacing(
                                                    2,
                                                    1,
                                                    2,
                                                    2,
                                                ),
                                                height: "fit-content",
                                                width: "fit-content",
                                                fontWeight: 600,
                                            }}
                                        >
                                            IF
                                        </Grid>
                                        <Grid
                                            item
                                            xs={10}
                                            sx={{
                                                padding: theme.spacing(2),
                                                margin: theme.spacing(2, 0),
                                                background:
                                                    theme.palette.gray[100],
                                                borderRadius: 2,
                                            }}
                                        >
                                            {conditionIndicators.map(
                                                (indicator, indicatorIndex) => {
                                                    const showSeparator =
                                                        indicatorIndex !==
                                                        conditionIndicators.length -
                                                            1;
                                                    return (
                                                        <IfStatement
                                                            key={indicator.id}
                                                            indicator={
                                                                indicator
                                                            }
                                                            removeIndicator={() => {
                                                                removeConditionIndicator(
                                                                    ruleIndex,
                                                                    indicator.id,
                                                                );
                                                            }}
                                                            updateIndicator={(
                                                                key,
                                                                value,
                                                            ) => {
                                                                updateIndicator(
                                                                    ruleIndex,
                                                                    indicatorIndex,
                                                                    key,
                                                                    value,
                                                                );
                                                            }}
                                                            fieldOptions={
                                                                fieldOptions
                                                            }
                                                            separatorLabel={
                                                                showSeparator
                                                                    ? "OR"
                                                                    : undefined
                                                            }
                                                        />
                                                    );
                                                },
                                            )}
                                            <AcxButton
                                                startIcon={<AddIcon />}
                                                onClick={() => {
                                                    addConditionIndicator(
                                                        ruleIndex,
                                                    );
                                                }}
                                                color="white"
                                                sx={{
                                                    color: theme.palette.primary
                                                        .main,
                                                }}
                                            >
                                                OR
                                            </AcxButton>
                                            <Box
                                                sx={{
                                                    padding: theme.spacing(1),
                                                    marginTop: theme.spacing(2),
                                                    color: theme.palette.primary
                                                        .dark,
                                                    background:
                                                        theme.palette.primary
                                                            .light,
                                                    fontWeight: 600,
                                                    borderRadius: 2,
                                                    width: "fit-content",
                                                }}
                                            >
                                                THEN
                                            </Box>
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        container
                                        sx={{
                                            padding: theme.spacing(2),
                                            gap: theme.spacing(2),
                                        }}
                                    >
                                        <StatementContainer
                                            statement={then}
                                            ruleIndex={ruleIndex}
                                            conditionRules={conditionRules}
                                            setConditionRules={
                                                setConditionRules
                                            }
                                            fieldsOptions={fieldOptions}
                                        />
                                        {then.else?.childStatements?.map(
                                            (childStatement, index) => {
                                                return (
                                                    <NestedStatementContainer
                                                        isLastIndex={
                                                            index ===
                                                            (then.else
                                                                ?.childStatements
                                                                ?.length || 1) -
                                                                1
                                                        }
                                                        statement={
                                                            childStatement
                                                        }
                                                        statementIndex={index}
                                                        indentNumber={index}
                                                        ruleIndex={ruleIndex}
                                                        conditionRules={
                                                            conditionRules
                                                        }
                                                        setConditionRules={
                                                            setConditionRules
                                                        }
                                                        fieldsOptions={
                                                            fieldOptions
                                                        }
                                                        elseStatement={
                                                            childStatement.else
                                                        }
                                                        key={childStatement.id}
                                                        isMaxLength={
                                                            (then.else
                                                                ?.childStatements
                                                                ?.length || 1) >
                                                            4
                                                        }
                                                    />
                                                );
                                            },
                                        )}
                                    </Grid>

                                    {elseStatement ? (
                                        <Box sx={{ margin: theme.spacing(2) }}>
                                            <StatementContainer
                                                isElseStatement
                                                statement={elseStatement}
                                                ruleIndex={ruleIndex}
                                                conditionRules={conditionRules}
                                                setConditionRules={
                                                    setConditionRules
                                                }
                                                fieldsOptions={fieldOptions}
                                            />
                                        </Box>
                                    ) : (
                                        <AcxButton
                                            startIcon={<AddIcon />}
                                            onClick={() => addElseStatement(id)}
                                            color="white"
                                            sx={{
                                                color: theme.palette.primary
                                                    .main,
                                                margin: theme.spacing(2),
                                            }}
                                        >
                                            ELSE
                                        </AcxButton>
                                    )}
                                    {elseStatement?.else?.childStatements?.map(
                                        (childStatement, index) => {
                                            return (
                                                <NestedStatementContainer
                                                    isLastIndex={
                                                        index ===
                                                        (elseStatement?.else
                                                            ?.childStatements
                                                            ?.length || 1) -
                                                            1
                                                    }
                                                    statement={childStatement}
                                                    isElseStatement
                                                    statementIndex={index}
                                                    indentNumber={index + 2}
                                                    ruleIndex={ruleIndex}
                                                    conditionRules={
                                                        conditionRules
                                                    }
                                                    setConditionRules={
                                                        setConditionRules
                                                    }
                                                    fieldsOptions={fieldOptions}
                                                    elseStatement={
                                                        childStatement.else
                                                    }
                                                    key={childStatement.id}
                                                    isMaxLength={
                                                        (elseStatement?.else
                                                            ?.childStatements
                                                            ?.length || 1) > 4
                                                    }
                                                />
                                            );
                                        },
                                    )}
                                    <DeleteButton
                                        onDelete={() => removeConditionRule(id)}
                                        showDelete={showAction}
                                        sx={{
                                            position: "absolute",
                                            top: theme.spacing(1),
                                            right: theme.spacing(1),
                                        }}
                                    />
                                </Box>
                            );
                        },
                    )}
                </Box>

                <AcxButton
                    startIcon={<AddIcon />}
                    onClick={addConditionRule}
                    color="white"
                    sx={{
                        color: theme.palette.primary.main,
                        marginTop: theme.spacing(2),
                    }}
                >
                    Condition
                </AcxButton>
            </DataSourceContainer>
        );
    },
);

export default ConditionalLogic;
