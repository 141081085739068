import { Box } from "@mui/material";
import React from "react";
import theme from "Theme/AppTheme";
import { v4 as uuidv4 } from "uuid";
import { observer } from "mobx-react";
import StatementHandler from "./StatementHandler";
import { ConditionRule, StatementType } from "../../types";

type NestedStatementContainerProps = {
    statement: StatementType;
    ruleIndex: number;
    statementIndex: number;
    indentNumber: number;
    isLastIndex: boolean;
    conditionRules: ConditionRule[];
    setConditionRules: (conditionRules: ConditionRule[]) => void;
    fieldsOptions: { label: string; value: string }[];
    elseStatement?: StatementType;
    isMaxLength?: boolean;
    isElseStatement?: boolean;
};

const NestedStatementContainer: React.FC<NestedStatementContainerProps> =
    observer(
        ({
            statement,
            statementIndex,
            ruleIndex,
            conditionRules,
            setConditionRules,
            fieldsOptions,
            elseStatement,
            isLastIndex,
            isMaxLength,
            isElseStatement,
            indentNumber,
        }) => {
            const updateRules = (runUpdate: Function) => {
                let needsCleanup = false;

                const updateElse = (statement?: StatementType) => {
                    return {
                        else: {
                            ...statement?.else,
                            id: statement?.else?.id || uuidv4(),
                            childStatements:
                                statement?.else?.childStatements?.map(
                                    (cs, index) => {
                                        if (statementIndex === index) {
                                            const update = runUpdate(cs);
                                            if (
                                                update?.conditionIndicators
                                                    ?.length === 0 &&
                                                typeof update?.result ===
                                                    "undefined"
                                            ) {
                                                needsCleanup = true;
                                            }
                                            return update;
                                        }
                                        return cs;
                                    },
                                ),
                        },
                    };
                };

                setConditionRules(
                    conditionRules.map((rule, i) => {
                        if (ruleIndex === i) {
                            const updatedElse = updateElse(
                                isElseStatement ? rule.else : rule.then,
                            );
                            return {
                                ...rule,
                                ...(isElseStatement
                                    ? {
                                          else: {
                                              ...rule.else,
                                              id: rule.else?.id || uuidv4(),
                                              ...updatedElse,
                                          },
                                      }
                                    : {
                                          then: {
                                              ...rule.then,
                                              id: rule.then?.id || uuidv4(),
                                              ...updatedElse,
                                          },
                                      }),
                            };
                        }
                        return rule;
                    }),
                );

                if (needsCleanup) {
                    removeSelf();
                }
            };

            const addThenConditionIndicator = () => {
                updateRules((cs: StatementType) => {
                    return {
                        ...cs,
                        conditionIndicators: [
                            ...(cs.conditionIndicators || []),
                            {
                                id: uuidv4(),
                                compareType: 0,
                                valuePath: "",
                                value: "",
                                isRequired: false,
                            },
                        ],
                    };
                });
            };

            const removeThenIndicator = (id: string) => {
                updateRules((cs: StatementType) => {
                    return {
                        ...cs,
                        conditionIndicators: cs.conditionIndicators?.filter(
                            (indicator) => indicator.id !== id,
                        ),
                    };
                });
            };

            const updateThenIndicator = (
                indicatorIndex: number,
                key: string,
                value: string | number,
            ) => {
                updateRules((cs: StatementType) => {
                    return {
                        ...cs,
                        conditionIndicators: cs.conditionIndicators?.map(
                            (indicator, j) => {
                                if (indicatorIndex === j) {
                                    return {
                                        ...indicator,
                                        [key]: value,
                                    };
                                }
                                return indicator;
                            },
                        ),
                    };
                });
            };

            const updateResult = (value?: string) => {
                updateRules((cs: StatementType) => {
                    return {
                        ...cs,
                        result: value,
                    };
                });
            };

            const removeResult = () => {
                updateResult(undefined);
            };

            const addResult = () => {
                updateResult("");
            };

            const updateElseResult = (value: string) => {
                updateRules((cs: StatementType) => {
                    return {
                        ...cs,
                        else: {
                            ...cs.else,
                            id: cs.else?.id || uuidv4(),
                            result: value,
                        },
                    };
                });
            };

            const removeElseResult = () => {
                updateRules((cs: StatementType) => {
                    return {
                        ...cs,
                        else: {
                            ...cs.else,
                            id: cs.else?.id || uuidv4(),
                            result: undefined,
                        },
                    };
                });
            };

            const addElse = () => {
                updateElseResult("");
            };

            const addChildStatement = () => {
                const newChildStatement = {
                    id: uuidv4(),
                    conditionIndicators: [
                        {
                            id: uuidv4(),
                            compareType: 0,
                            valuePath: "",
                            value: "",
                            isRequired: false,
                        },
                    ],
                };
                setConditionRules(
                    conditionRules.map((rule, i) => {
                        const updateElse = (statement?: StatementType) => {
                            return {
                                else: {
                                    ...statement?.else,
                                    id: statement?.else?.id || uuidv4(),
                                    childStatements: [
                                        ...(statement?.else?.childStatements ||
                                            []),
                                        newChildStatement,
                                    ],
                                },
                            };
                        };
                        if (ruleIndex === i) {
                            return {
                                ...rule,
                                ...(isElseStatement
                                    ? {
                                          else: {
                                              ...rule.else,
                                              id: rule.else?.id || uuidv4(),
                                              ...updateElse(rule.else),
                                          },
                                      }
                                    : {
                                          then: {
                                              ...rule.then,
                                              id: rule.then?.id || uuidv4(),
                                              ...updateElse(rule.then),
                                          },
                                      }),
                            };
                        }
                        return rule;
                    }),
                );
            };

            const removeSelf = () => {
                const updateElse = (statement?: StatementType) => {
                    return {
                        else: {
                            ...statement?.else,
                            id: statement?.else?.id || uuidv4(),
                            childStatements:
                                statement?.else?.childStatements?.filter(
                                    (_, index) => index !== statementIndex,
                                ),
                        },
                    };
                };

                setConditionRules(
                    conditionRules.map((rule, i) => {
                        if (ruleIndex === i) {
                            return {
                                ...rule,
                                ...(isElseStatement
                                    ? {
                                          else: {
                                              ...rule.else,
                                              id: rule.else?.id || uuidv4(),
                                              ...updateElse(rule.else),
                                          },
                                      }
                                    : {
                                          then: {
                                              ...rule.then,
                                              id: rule.then?.id || uuidv4(),
                                              ...updateElse(rule.then),
                                          },
                                      }),
                            };
                        }
                        return rule;
                    }),
                );
            };

            const leftSpacing = theme.spacing((indentNumber + 1) * 2);

            return (
                <Box
                    sx={{
                        maxWidth: `calc(95% - ${leftSpacing})`,
                        width: "100%",
                        margin: theme.spacing(2, 0, 2, leftSpacing),
                    }}
                >
                    <Box
                        sx={{
                            padding: theme.spacing(1),
                            color: theme.palette.primary.dark,
                            background: theme.palette.primary.light,
                            fontWeight: 600,
                            borderRadius: 2,
                            width: "fit-content",
                            marginBottom: theme.spacing(2),
                        }}
                    >
                        ELSE
                    </Box>
                    <StatementHandler
                        statement={statement}
                        fieldsOptions={fieldsOptions}
                        elseStatement={elseStatement}
                        addThenConditionIndicator={addThenConditionIndicator}
                        removeThenIndicator={removeThenIndicator}
                        updateThenIndicator={updateThenIndicator}
                        addResult={addResult}
                        removeResult={removeResult}
                        updateResult={updateResult}
                        addElse={addElse}
                        removeElseResult={removeElseResult}
                        updateElseResult={updateElseResult}
                        addChildStatement={addChildStatement}
                        removeSelf={removeSelf}
                        isLastIndex={isLastIndex}
                        isMaxLength={isMaxLength}
                    />
                </Box>
            );
        },
    );

export default NestedStatementContainer;
