import AcxDataGridStore from "components/UI/AcxDataGrid/AcxDataGridStore";
import { action, makeObservable, observable, runInAction } from "mobx";
import { Configuration } from "models/OrganizationModels/OrganizationMetadataConfiguration";
import { OrganizationService } from "services/OrganizationService";
import { BaseStore } from "stores/BaseStore";
import type { IRootStore } from "stores/RootStore";
import { AcxStore } from "stores/RootStore";
import { v4 as uuidv4 } from "uuid";
import type {
    APIRequest,
    ConcatFieldType,
    ConditionIndicatorType,
    ConditionRule,
    SourceFieldType,
    StatementType,
} from "./types";

export const operatorOptions = [
    { label: "Exists", value: 0 },
    { label: "Equals", value: 1 },
    { label: "Contains", value: 2 },
];

export const staticTargetFields = [
    { label: "Agent", value: 0 },
    { label: "CallDirection", value: 1 },
    { label: "InteractionDate", value: 2 },
    { label: "TimeOnHold", value: 3 },
    { label: "TransferCount", value: 4 },
    { label: "AgentId", value: 5 },
    { label: "PhoneNumber", value: 6 },
    { label: "ClientCallId", value: 7 },
    { label: "GroupName", value: 8 },
    { label: "HIPAAValidated", value: 9 },
    { label: "EmployeeGroupName", value: 10 },
    { label: "MemberId", value: 11 },
    { label: "Tags", value: 12 },
    { label: "HierarchyId", value: 13 },
    { label: "FileName", value: 14 },
];

const initialSourceFields: SourceFieldType[] = [
    {
        isRequired: true,
        path: "",
        alias: "",
        extractionPattern: "",
        id: uuidv4(),
    },
];

@AcxStore
export class OrganizationDataSourcesStore extends BaseStore {
    readonly datasourcesDgStore: AcxDataGridStore;
    private readonly orgService: OrganizationService =
        new OrganizationService();

    @observable activeStep: number = 0;
    @observable shouldApplyConditions: boolean = false;
    @observable shouldConcatenateFields: boolean = false;
    @observable sourceFields: SourceFieldType[] = initialSourceFields;
    @observable hasSourceFieldErrors: boolean = false;
    @observable conditionRules: ConditionRule[] = [];
    @observable concatFields: ConcatFieldType[] = [];
    @observable selectedDatasource?: Configuration;
    @observable targetFields: { label: string; value: number }[] =
        staticTargetFields;

    constructor(private rootStore: IRootStore) {
        super("OrganizationDatasourcesStore");
        makeObservable(this);

        this.datasourcesDgStore = new AcxDataGridStore(
            "OrganizationDatasourcesStore",
            "OrganizationDatasource",
        );

        this.datasourcesDgStore.checkboxSelection = false;
        this.datasourcesDgStore.hideFilter = true;
        this.datasourcesDgStore.hideVertIcon = true;
        this.datasourcesDgStore.rows = [];
    }

    setSourceFieldsFromData = (datasource: Configuration) => {
        const sourceFields: SourceFieldType[] = datasource.fields.map(
            (field) => ({
                isRequired: field.isRequired,
                alias: "",
                id: field.id,
                extractionPattern: field.extractionPattern || "",
                path: field.xPathString,
            }),
        );
        if (sourceFields.length === 0) {
            this.setSourceFields(initialSourceFields);
        } else {
            this.setSourceFields(sourceFields);
        }
    };

    @action
    checkIfSourceFieldsAreValid = () => {
        const areFieldsValid = this.sourceFields.every(
            (field) => field.alias && field.path,
        );
        this.hasSourceFieldErrors = !areFieldsValid;
        return areFieldsValid;
    };

    @action
    setActiveStep = (step: number) => {
        this.activeStep = step;
    };

    @action
    setSourceFields = (fields: SourceFieldType[]) => {
        this.sourceFields = fields;
    };

    @action
    setShouldApplyConditions = (applyConditions: boolean) => {
        this.shouldApplyConditions = applyConditions;

        if (!this.checkIfSourceFieldsAreValid()) {
            this.shouldApplyConditions = false;
        } else if (applyConditions && this.conditionRules.length === 0) {
            this.conditionRules = [
                {
                    id: uuidv4(),
                    targetMetaDataField: 0,
                    name: `Condition ${this.conditionRules.length + 1}`,
                    conditionIndicators: [
                        {
                            id: uuidv4(),
                            compareType: 0,
                            valuePath: "",
                            value: "",
                            isRequired: false,
                        },
                    ],
                    then: {
                        id: uuidv4(),
                        conditionIndicators: [],
                    },
                },
            ];
        }
    };

    @action
    setConditionRules = (rules: ConditionRule[]) => {
        this.conditionRules = rules;
    };

    @action
    setConcatFields = (fields: ConcatFieldType[]) => {
        // TODO - Figure out how this connects to the API with the condition rules
        this.concatFields = fields;
    };

    @action
    setShouldConcatenateFields = (concatenateFields: boolean) => {
        this.shouldConcatenateFields = concatenateFields;
        if (!this.checkIfSourceFieldsAreValid()) {
            this.shouldConcatenateFields = false;
        } else if (concatenateFields && this.concatFields.length === 0) {
            this.concatFields = [
                {
                    id: uuidv4(),
                    newName: "",
                    fields: [],
                    separator: "",
                },
            ];
        }
    };

    @action
    setSelectedDatasource = (datasource: Configuration) => {
        this.selectedDatasource = datasource;
        this.setSourceFieldsFromData(datasource);
    };

    @action
    loadDataSources = async () => {
        this.datasourcesDgStore.isLoading = true;
        this.setupAsyncTask("Load Data Sources", async () => {
            const config =
                await this.orgService.getOrganizationMetadataConfiguration();
            runInAction(() => {
                this.datasourcesDgStore.rows = config;
                this.datasourcesDgStore.isLoading = false;
            });
        });
    };

    @action
    prepareDataForSave = () => {
        const formatConditionIndicators = (
            indicators?: ConditionIndicatorType[],
        ) => {
            return indicators?.map((indicator) => ({
                Id: indicator.id,
                CompareType: indicator.compareType,
                ValuePath: indicator.valuePath,
                Value: indicator.value,
                IsRequired: indicator.isRequired,
                ExtractionPattern: undefined,
            }));
        };

        const formatStatement = (statement: StatementType) => {
            return {
                Id: statement.id,
                ConditionIndicators: formatConditionIndicators(
                    statement?.conditionIndicators,
                ),
                ConcatRule: statement?.result
                    ? {
                          Id: uuidv4(),
                          LiteralValue: statement.result,
                          IsLiteral: true,
                      }
                    : undefined,
                HasCondition: !!statement.conditionIndicators?.length,
            };
        };

        const updatedSourceFields = this.sourceFields.map((field) => {
            let conditionRule: any;
            if (this.shouldApplyConditions) {
                const _conditionRule = this.conditionRules.find((rule) =>
                    rule.conditionIndicators.find(
                        (indicator) => indicator.valuePath === field.path,
                    ),
                );

                if (_conditionRule) {
                    conditionRule = {
                        Id: _conditionRule.id,
                        ConditionIndicators: formatConditionIndicators(
                            _conditionRule.conditionIndicators,
                        ),
                        Then: {
                            ...formatStatement(_conditionRule.then),
                        },
                        Else: _conditionRule.else
                            ? {
                                  ...formatStatement(_conditionRule.else),
                              }
                            : undefined,
                    };
                    if (_conditionRule.then?.childStatements) {
                        const thenStatements =
                            _conditionRule.then.childStatements;
                        let thenObj: any = conditionRule.then;
                        for (let i = 0; i < thenStatements.length; i++) {
                            const currentStatement = thenStatements[i];
                            thenObj.Then = {
                                ...formatStatement(currentStatement),
                            };
                            thenObj = thenObj.Then;
                        }
                    }
                    if (_conditionRule.else?.childStatements) {
                        const elseStatements =
                            _conditionRule.else.childStatements;
                        let elseObj: any = conditionRule.else;
                        for (let i = 0; i < elseStatements.length; i++) {
                            const currentStatement = elseStatements[i];
                            elseObj.Else = {
                                ...formatStatement(currentStatement),
                            };
                            elseObj = elseObj.Else;
                        }
                    }
                }
            }

            return {
                SourceFieldName: field.alias,
                TargetFieldName: "TBD",
                Id: field.id,
                IsRequired: field.isRequired,
                ExtractionPattern: field.extractionPattern,
                ExpressionValuePath: field.path,
                ConditionRule: conditionRule,
                IsIndexed: false, // TODO - What is this?
                DataType: 0, // TODO - What is this?
                HasCondition: true, // TODO - What is this?
                TargetMetaDataField: undefined,
                IsCustomField: false, // TODO - What is this?
            };
        });

        // This will be added to for the final request
        const request: APIRequest = {
            SourceFieldConfigurations: updatedSourceFields,
            OrganizationId: "",
            Id: "",
            MetaDataFileType: 0,
            MetaDataFileTypeExtension: 0,
            Prefix: "",
            NameSpace: "",
            Name: "",
            MediaFileTypes: undefined,
            IsDebug: false,
            ChatFileSchemaHandler: 0,
            CustomDateFormatPatterns: undefined,
            AgentCodeRequired: false,
        };
        console.log(request);
    };
}
