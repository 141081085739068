import React from "react";
import { IconButton, SxProps } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Clear";
import theme from "Theme/AppTheme";

interface DeleteButtonProps {
    onDelete: () => void;
    showDelete: boolean;
    sx?: SxProps;
}

const DeleteButton: React.FC<DeleteButtonProps> = ({
    onDelete,
    showDelete,
    sx,
}) => {
    return (
        <IconButton
            size="small"
            sx={{
                padding: theme.spacing(0.25),
                height: "fit-content",
                background: theme.palette.gray[400],
                color: theme.palette.white.main,
                visibility: showDelete ? "visible" : "hidden",
                ":hover": {
                    background: theme.palette.gray[500],
                },
                ...sx,
            }}
            onClick={() => onDelete()}
        >
            <DeleteIcon fontSize="inherit" />
        </IconButton>
    );
};

export default DeleteButton;
