import { Box, Checkbox, IconButton } from "@mui/material";
import AcxMainTextField from "components/UI/AcxMainTextField";
import AddIcon from "@mui/icons-material/Add";
import MinusIcon from "@mui/icons-material/Remove";
import React, { useState, useRef } from "react";
import theme from "Theme/AppTheme";
import { SourceFieldType } from "../types";
import DeleteButton from "../DeleteButton";
import useHoverAction from "../useHoverAction";

type SourceFieldProps = SourceFieldType & {
    onRemove: () => void;
    onChange: (field: SourceFieldType) => void;
    showError: boolean;
};

const SourceField: React.FC<SourceFieldProps> = ({
    onRemove,
    onChange,
    path,
    alias,
    extractionPattern,
    isRequired,
    id,
    showError,
}) => {
    const [showPattern, setShowPattern] = useState(extractionPattern !== ""); // show extraction pattern input if extraction pattern exists
    const containerRef = useRef<HTMLLIElement>(null);
    const { showAction, handlers } = useHoverAction(containerRef);

    const handleChange = (value: string | boolean, field: string) => {
        onChange({
            path,
            alias,
            extractionPattern,
            isRequired,
            id,
            [field]: value,
        });
    };

    return (
        <Box
            ref={containerRef}
            {...handlers}
            sx={{
                borderRadius: 2,
                padding: theme.spacing(1, 0.5),
                background: theme.palette.white.main,
                display: "flex",
            }}
            component="li"
        >
            <Checkbox
                size="small"
                checked={isRequired}
                onChange={(e) => handleChange(e.target.checked, "isRequired")}
                sx={{
                    padding: theme.spacing(0.5),
                    margin: theme.spacing(0.5, 1.5, 0, 0),
                    height: "fit-content",
                    "svg.MuiSvgIcon-root": {
                        width: "20px",
                        height: "20px",
                    },
                }}
            />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    gap: theme.spacing(1),
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        ".source-field-input-error": {
                            borderWidth: "2px",
                            borderStyle: "solid",
                            borderRadius: 2,
                            borderColor: theme.palette.error.main,
                        },
                    }}
                >
                    <AcxMainTextField
                        id={`source-field-path-${id}`}
                        type="text"
                        placeholderText="Field Path"
                        value={path}
                        onChange={(e) => handleChange(e.target.value, "path")}
                        containerStyle={{
                            flex: 5,
                            marginRight: theme.spacing(3),
                        }}
                        textContainerClass={
                            showError && !path
                                ? "source-field-input-error"
                                : undefined
                        }
                        fullWidth
                    />
                    <AcxMainTextField
                        id={`source-field-alias-${id}`}
                        type="text"
                        placeholderText="Source Field Alias"
                        value={alias}
                        onChange={(e) => handleChange(e.target.value, "alias")}
                        containerStyle={{
                            flex: 2,
                            marginRight: theme.spacing(0.5),
                        }}
                        textContainerClass={
                            showError && !alias
                                ? "source-field-input-error"
                                : undefined
                        }
                        fullWidth
                    />
                    <IconButton
                        size="small"
                        onClick={() => {
                            setShowPattern(true);
                        }}
                        sx={{
                            padding: theme.spacing(0.5),
                            height: "fit-content",
                            visibility: showPattern ? "hidden" : "visible",
                        }}
                    >
                        <AddIcon fontSize="inherit" />
                    </IconButton>
                </Box>
                {showPattern && (
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <AcxMainTextField
                            id={`source-field-pattern-${id}`}
                            type="text"
                            placeholderText="Enter Regex extraction pattern"
                            value={extractionPattern}
                            onChange={(e) =>
                                handleChange(
                                    e.target.value,
                                    "extractionPattern",
                                )
                            }
                            containerStyle={{
                                flex: 2,
                                marginRight: theme.spacing(0.5),
                            }}
                            fullWidth
                        />
                        <IconButton
                            size="small"
                            onClick={() => {
                                setShowPattern(false);
                            }}
                            sx={{
                                padding: theme.spacing(0.5),
                                height: "fit-content",
                            }}
                        >
                            <MinusIcon fontSize="inherit" />
                        </IconButton>
                    </Box>
                )}
            </Box>
            <DeleteButton
                onDelete={onRemove}
                showDelete={showAction}
                sx={{ margin: theme.spacing(1, 0.5) }}
            />
        </Box>
    );
};

export default SourceField;
