import { Grid } from "@mui/material";
import AcxSelectMulti from "components/UI/Select/BaseSelectComponents/AcxSelectMulti";
import { observer } from "mobx-react";
import { Answer } from "models/Answer";
import Question from "models/Question";
import SoundClip from "models/SoundClip";
import React, { FunctionComponent, useCallback } from "react";
import { useStore } from "utils/useStore";
import ConversationLabelingStore from "../store/ConversationLabelingStore";
import ConversationsStore from "components/Conversations/Stores/ConversationsStore";
import theme from "Theme/AppTheme";

interface OwnProps {
    answer?: Answer;
    question: Question;

    conversationModuleId?: string;
    isDisabled?: boolean;
}

type Props = OwnProps;

const selectCustomStyle = {
    menuPortal: (provided, state) => ({ ...provided, zIndex: 9999 }),
};

const ConvoClip: FunctionComponent<Props> = observer((props) => {
    const store = useStore(ConversationLabelingStore);
    const convoStore = useStore(ConversationsStore);

    const setClipsForAnswer = useCallback(
        (soundClips: SoundClip[] | undefined) => {
            const tags = props.answer?.activeTags ?? [];
            if (!props.answer) {
                const newAns = store?.setAnswerForQuestion(
                    props.question,
                    tags,
                    props.answer,
                    props.conversationModuleId,
                );
                newAns?.setSoundClips(soundClips);
            } else {
                props.answer.setSoundClips(soundClips);
            }
        },
        [props.answer, props.question, props.conversationModuleId, store],
    );

    return (
        <Grid
            container
            item
            xs={12}
            direction={"column"}
            justifyContent={"flex-start"}
            alignItems={"stretch"}
            paddingLeft={theme.spacing(3)}
            paddingRight={theme.spacing(3)}
        >
            <Grid item xs={12} paddingBottom={theme.spacing(1.5)}>
                <AcxSelectMulti
                    options={
                        convoStore.activeSoundClipsNotAttachedToAnswer ?? []
                    }
                    containerHeight={"auto"}
                    customStyle={selectCustomStyle}
                    menuPlacement={"auto"}
                    menuPortalTarget={document.body}
                    defaultValue={props.answer?.activeSoundClips}
                    isClearable
                    fullWidth
                    inputLabel={"Add clip(s)"}
                    onChange={setClipsForAnswer}
                    id={"QuoteSelectorForModuleAnswer" + props.question.id}
                    valueField={"segmentName"}
                    labelField={"segmentName"}
                    isDisabled={props.isDisabled}
                    placeholder="Select Clip"
                />
            </Grid>
        </Grid>
    );
});

export default ConvoClip;
