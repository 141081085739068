import { observer } from "mobx-react";
import { Answer } from "models/Answer";
import Question from "models/Question";
import React, { FunctionComponent, useEffect, useState } from "react";
import ConvoMultiTagClip from "./ConvoMultiTagClip";
import AcxButton from "components/UI/AcxButton";
import { Grid } from "@mui/material";
import theme from "Theme/AppTheme";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

interface OwnProps {
    answer?: Answer;
    question: Question;

    conversationModuleId?: string;
    isDisabled?: boolean;
}

type Props = OwnProps;

const ConvoMultiTagClipWrapper: FunctionComponent<Props> = observer((props) => {
    const [currentlyActiveClipIndices, setCurrentlyActiveClipIndices] =
        useState<number[]>([]);

    useEffect(() => {
        if (
            (props.answer?.activeSoundClips?.length ?? 0) >
            currentlyActiveClipIndices.length
        ) {
            setCurrentlyActiveClipIndices([]);
            props.answer?.activeSoundClips.forEach((sc, index) => {
                setCurrentlyActiveClipIndices((prev) => [...prev, index]);
            });
        }
        // only want to update this when clips are added/removed
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.answer?.activeSoundClips.length]);

    const removeClip = (clipIndex: number) => {
        setCurrentlyActiveClipIndices((prev) => {
            prev.splice(clipIndex, 1);
            return [...prev];
        });
    };

    return (
        <>
            {currentlyActiveClipIndices.map((index) => {
                return (
                    <ConvoMultiTagClip
                        {...props}
                        clipIndex={index}
                        removeClip={removeClip}
                    />
                );
            })}

            <Grid
                item
                container
                xs={12}
                padding={theme.spacing(3)}
                paddingTop={0}
                justifyContent={"space-between"}
            >
                <AcxButton
                    onClick={() => {
                        setCurrentlyActiveClipIndices((prev) => {
                            const currentIndex = prev.length;
                            return [...prev, currentIndex];
                        });
                    }}
                    color="white"
                    startIcon={<AddIcon />}
                    disabled={!props.answer}
                >
                    Add Clip
                </AcxButton>
                {!!currentlyActiveClipIndices.length && (
                    <AcxButton
                        onClick={() => {
                            if (
                                props.answer &&
                                props.answer.activeSoundClips.length ===
                                    currentlyActiveClipIndices.length
                            ) {
                                const removedClip =
                                    props.answer.activeSoundClips[
                                        props.answer.activeSoundClips.length - 1
                                    ];

                                if (removedClip) {
                                    removedClip.setClipTags();
                                }
                                const newSoundClips =
                                    props.answer.activeSoundClips.slice(0, -1);
                                props.answer.setSoundClips(newSoundClips);
                            }
                            setCurrentlyActiveClipIndices((prev) => {
                                const newList = prev.slice(0, -1);
                                return [...newList];
                            });
                        }}
                        color="white"
                        startIcon={<RemoveIcon />}
                    >
                        Remove Clip
                    </AcxButton>
                )}
            </Grid>
        </>
    );
});

export default ConvoMultiTagClipWrapper;
