import React from "react";
import theme from "Theme/AppTheme";
import {
    Box,
    Container,
    ContainerOwnProps,
    Divider,
    Typography,
} from "@mui/material";

type DataSourceContainerProps = ContainerOwnProps & {
    heading: string;
    subHeading?: string;
};

const DataSourceContainer: React.FC<DataSourceContainerProps> = ({
    heading,
    subHeading,
    children,
    ...rest
}) => {
    return (
        <Container
            maxWidth="xl"
            sx={{
                "&.MuiContainer-root": {
                    padding: theme.spacing(2, 0),
                },
            }}
            {...rest}
        >
            <Box
                sx={{
                    padding: theme.spacing(2),
                    borderRadius: 2,
                    background: theme.palette.white.main,
                    boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.07);",
                }}
            >
                <Typography
                    component="h2"
                    sx={{
                        fontWeight: 600,
                        fontSize: "16px",
                        color: theme.palette.gray[700],
                    }}
                >
                    {heading}
                </Typography>
                {subHeading && (
                    <Typography
                        component="h3"
                        sx={{
                            fontSize: "14px",
                            color: theme.palette.gray[800],
                        }}
                    >
                        {subHeading}
                    </Typography>
                )}
                <Divider
                    sx={{
                        margin: theme.spacing(1, 0, 2),
                    }}
                />
                {children}
            </Box>
        </Container>
    );
};

export default DataSourceContainer;
