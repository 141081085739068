import { observer } from "mobx-react";
import { IAppliedFilterChipComponent } from "../Chips/IAppliedFilterChipComponent";
import AppliedFilterChip from "../Chips/AppliedFilterChipBase";
import { useStore } from "utils/useStore";
import { ApplicationFiltersStore } from "stores/ApplicationFilters/ApplicationFiltersStore";
import React from "react";

export const PrimaryTopicsChip = observer(function (
    props: IAppliedFilterChipComponent,
) {
    const applicationFiltersStore = useStore(ApplicationFiltersStore);

    return applicationFiltersStore.primaryTopics.map((topic) => (
        <AppliedFilterChip
            disabled={props.disabled}
            isApplicable={props.isApplicable}
            onRemove={() =>
                applicationFiltersStore.setPrimaryTopics(
                    applicationFiltersStore.primaryTopics.filter(
                        (t) => t.topicNumber !== topic.topicNumber,
                    ),
                )
            }
        >
            Topic: {topic.topicLabel}
        </AppliedFilterChip>
    ));
});
