import {
    Checkbox,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Theme,
    Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import AcxAnswerSelector from "components/UI/AcxAnswerSelector";
import AcxWideTooltip from "components/UI/AcxWideTooltip";
import { observer } from "mobx-react";
import { Answer } from "models/Answer";
import Question, { QuestionType } from "models/Question";
import { Tag } from "models/Tag";
import React from "react";
import sanitizeHtml from "sanitize-html";
import InfoSvg from "SvgIcons/InfoSvg";
import theme from "Theme/AppTheme";
import ConvoNote from "./ConvoNote";
import { ConvoModuleUIModel } from "./ConvoModuleUIModel";
import { AnswerTypeNames } from "models/AnswerType";
import ConvoClip from "./ConvoClip";

const useStyles = makeStyles((theme: Theme) => ({
    root: {},

    padding3: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },

    questionText: (props: Props) => ({
        color: "#1F1F1F",
        fontSize: "14px",
        fontWeight: "bold",
        letterSpacing: "0",
        lineHeight: "20px",
    }),

    fullSize: {
        height: "100%",
        width: "100%",
    },
    questionHeader: (props: Props) => ({
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(2),
    }),
    infoSVG: {
        marginRight: theme.spacing(2.7),
        paddingTop: theme.spacing(1),
        marginBottom: theme.spacing(0.5),
    },
    sPContainer: {
        display: "flex",
        flexDirection: "row",
        wrap: "nowrap",
        alignItems: "center",
    },
}));

interface OwnProps {
    question: Question;
    moduleUIModel?: ConvoModuleUIModel;
    leftBorderColor?: string;
    isDisabled?: boolean;
    getAnswerForQuestion: (
        question: Question,
        conversationModuleId: string | undefined,
    ) => Answer | undefined;
    setAnswerForQuestion: (
        question: Question,
        tags: Tag[],
        answer: Answer | undefined,
        conversationModuleId?: string,
    ) => Answer;
    showClips?: boolean;
}

type Props = OwnProps;

const ConvoScaledResponseAnswer = observer((props: Props) => {
    const classes = useStyles(props);

    const answer = props.getAnswerForQuestion(
        props.question,
        props.moduleUIModel?.conversationModuleId,
    );

    const activeTag =
        (answer?.activeTags.length ?? 0) > 0
            ? answer?.activeTags[0]
            : undefined;

    const showNotesAndClips =
        props.question.answerType.answerTypeName !==
            AnswerTypeNames.QuestionGrouping &&
        !!answer &&
        !!answer.isAnswered;

    return (
        <Grid
            id={`question-${props.question.id}`}
            item
            xs={12}
            style={{
                border: "1px solid transparent",
                borderRadius: "4px",
                paddingLeft:
                    props.question.parentId || props.leftBorderColor
                        ? "16px"
                        : "0px",
                borderLeft: props.leftBorderColor
                    ? `2px solid ${props.leftBorderColor}`
                    : "none",
            }}
            className={classes.root}
            container
            direction={"column"}
            justifyContent={"flex-start"}
            alignItems="stretch"
        >
            <Grid
                item
                xs={12}
                container
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                wrap={"nowrap"}
                className={clsx(classes.questionHeader, {
                    [classes.padding3]: true,
                })}
            >
                <AcxWideTooltip
                    title={
                        sanitizeHtml(props.question.helperText) ? (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: sanitizeHtml(
                                        props.question.helperText,
                                        {
                                            allowedAttributes: {
                                                table: ["style"],
                                                th: ["style"],
                                                td: ["style"],
                                            },
                                            allowedStyles: {
                                                "*": {
                                                    // Match any number with px, em, or %
                                                    width: [/^\d+(?:px|em|%)$/],
                                                    border: [
                                                        /^1px solid rgba\(0, 0, 0, 1\)$/,
                                                    ],
                                                },
                                            },
                                        },
                                    ),
                                }}
                            />
                        ) : (
                            ""
                        )
                    }
                    placement={"top"}
                >
                    <Grid item className={classes.infoSVG}>
                        <InfoSvg width="24px" height="24px" />
                    </Grid>
                </AcxWideTooltip>
                <Grid item xs={10}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={1}
                    >
                        <Grid item>
                            <Typography
                                className={classes.questionText}
                                style={{
                                    color: answer?.isDisputed
                                        ? "theme.palette.error.main"
                                        : "theme.palette.text",
                                }}
                            >
                                {props.question?.questionText}
                                {props.question.required &&
                                    !answer?.isAnswered && (
                                        <span
                                            style={{
                                                color: theme.palette.error.main,
                                            }}
                                        >
                                            *
                                        </span>
                                    )}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {props.question.type !== QuestionType.QuestionHeading && (
                <Grid
                    item
                    xs={12}
                    direction={"row"}
                    wrap={"nowrap"}
                    container
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    className={clsx(
                        { [classes.padding3]: true },
                        classes.fullSize,
                    )}
                >
                    <Grid item xs={12}>
                        <AnswerSelectorComponent
                            question={props.question}
                            answer={answer}
                            activeTag={activeTag}
                            moduleUIModel={props.moduleUIModel}
                            leftBorderColor={props.leftBorderColor}
                            isDisabled={props.isDisabled}
                            getAnswerForQuestion={props.getAnswerForQuestion}
                            setAnswerForQuestion={props.setAnswerForQuestion}
                        />
                    </Grid>
                </Grid>
            )}
            {showNotesAndClips && (
                <>
                    <ConvoNote
                        question={props.question}
                        answer={answer}
                        columnDirection={"column"}
                        isDisabled={props.isDisabled}
                        conversationModuleId={
                            props.moduleUIModel?.conversationModuleId
                        }
                    />
                    {props.showClips && (
                        <ConvoClip
                            question={props.question}
                            answer={answer}
                            isDisabled={!answer}
                        />
                    )}
                </>
            )}
        </Grid>
    );
});

const AnswerSelectorComponent = observer(
    (
        props: OwnProps & {
            children?: React.ReactNode;

            answer: Answer | undefined;
            activeTag: Tag | undefined;
        },
    ) => {
        const options =
            props.moduleUIModel?.getTagsFormattedForScaledResponse(
                props.question.id,
            ) ?? [];

        const conversationModuleId = props.moduleUIModel?.conversationModuleId;

        if (props.question.answerType.variation === "Checkbox") {
            function onCheckboxAnswerSelect(
                event: React.ChangeEvent<HTMLInputElement>,
            ) {
                const value = event.target.checked;
                const selectedTag = options.find(
                    (value1) =>
                        value1.data.value?.toLowerCase() ===
                        `${value}`.toLowerCase(),
                )?.data;

                if (selectedTag) {
                    props.setAnswerForQuestion(
                        props.question,
                        [selectedTag],
                        props.answer,
                        conversationModuleId,
                    );
                } else {
                    props.setAnswerForQuestion(
                        props.question,
                        [],
                        props.answer,
                        conversationModuleId,
                    );
                }
            }

            return (
                <>
                    <Checkbox
                        checked={
                            props.activeTag?.value?.toLocaleLowerCase() ===
                                "true" ||
                            props.activeTag?.value?.toLocaleLowerCase() ===
                                "yes"
                        }
                        disabled={props.isDisabled}
                        onChange={onCheckboxAnswerSelect}
                    />
                </>
            );
        } else if (props.question.answerType.variation === "Radio") {
            function onRadioAnswerSelect(
                event: React.ChangeEvent<HTMLInputElement>,
            ) {
                const value = event.target.value;
                const selectedTag = options.find(
                    (value1) =>
                        value1.data.value?.toLowerCase() ===
                        `${value}`.toLowerCase(),
                )?.data;

                if (selectedTag) {
                    props.setAnswerForQuestion(
                        props.question,
                        [selectedTag],
                        props.answer,
                        conversationModuleId,
                    );
                } else {
                    props.setAnswerForQuestion(
                        props.question,
                        [],
                        props.answer,
                        conversationModuleId,
                    );
                }
            }

            return (
                <RadioGroup
                    row
                    value={props.activeTag?.value ?? null}
                    onChange={onRadioAnswerSelect}
                >
                    {options.map((value) => {
                        return (
                            <FormControlLabel
                                key={value.data.id}
                                value={value.data.value}
                                control={<Radio color="secondary" />}
                                label={value.data.value}
                                labelPlacement="end"
                                disabled={props.isDisabled}
                            />
                        );
                    })}
                </RadioGroup>
            );
        } else {
            function onAnswerSelect(arg: Tag) {
                if (arg === undefined) {
                    props.setAnswerForQuestion(
                        props.question,
                        [],
                        props.answer,
                        conversationModuleId,
                    );
                } else {
                    props.setAnswerForQuestion(
                        props.question,
                        [arg],
                        props.answer,
                        conversationModuleId,
                    );
                }
            }

            return (
                <>
                    <AcxAnswerSelector
                        options={options}
                        onSelect={onAnswerSelect}
                        defaultValue={props.activeTag}
                        buttonRootStyles={{
                            minWidth: "45%",
                            maxWidth: "45%",
                            minHeight: "36px",
                        }}
                        isDisabled={props.isDisabled}
                        isConvoWorkflow
                    />
                </>
            );
        }
    },
);

export default ConvoScaledResponseAnswer;
