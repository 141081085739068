import { useLocation, useParams } from "react-router";
import { ClassifierBuilderV2Store } from "../../Stores/ClassifierBuilderV2Store";
import { useStore } from "utils/useStore";
import { Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";

import { Theme } from "@mui/material";
import { useNavigate } from "react-router";
import ClassifierBuilderDetails from "../../Views/Builder/ClassifierBuilderV2Details";
import ClassifierBuilderRuleset from "../../Views/Builder/ClassifierBuilderV2Ruleset";

import { AuthStore } from "stores/AuthStore";
import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";
import ClassifierBuilderV2Toolbar from "./ClassifierBuilderV2Toolbar";
import ClassifierCategory from "models/ClassifierCategoryId";
import ClassifierGroup from "models/ClassifierGroup";

const useStyles = makeStyles((theme: Theme) => ({
    content: {
        paddingLeft: theme.spacing(20),
        paddingRight: theme.spacing(20),
        paddingTop: theme.spacing(3),
        height: "calc(100% - 64px)",
    },
}));

const ClassifierBuilderTableV2Edit = observer(() => {
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const store = useStore(ClassifierBuilderV2Store);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [category, setCategory] = useState<ClassifierCategory | null>(null);
    const [group, setGroup] = useState<ClassifierGroup>();
    const authStore = useStore(AuthStore);
    const orgStore = authStore.orgStore;
    const { id } = useParams();

    const onBack = () => {
        if (location.key !== "default") {
            navigate("../");
        } else {
            navigate(-1);
        }
        store.clearSelectedClassifier();
    };

    const onSave = async () => {
        if (id) {
            try {
                store.setTestClassifierError("");

                await store.validateRbcFtsSearchString();

                store.isRbcFtsStringValid &&
                    !store.testClassifierError &&
                    store.updateClassifier(
                        name,
                        store.rbcSearchTermStr,
                        id,
                        description,
                        category,
                        group,
                    );
            } catch (error) {
                throw error;
            }
        }
    };

    useEffect(() => {
        if (orgStore && orgStore.selectedOrganization && store && id) {
            store.orgSelectStore.selectOrg(orgStore.selectedOrganization);
            store.setSelectedClassifier(id);
        } else {
            store.clearSelectedClassifier();
        }
    }, [orgStore, orgStore?.selectedOrganization, store, id]);

    useEffect(() => {
        if (store.selectedClassifier) {
            setName(store.selectedClassifier.name);
            setDescription(store.selectedClassifier.description);
            setCategory(store.selectedClassifier.classifierCategory);
            setGroup(store.selectedClassifier.classifierGroup);
            store.setRbcSearchTermStr(
                store.rbcSearchTermStr
                    ? store.rbcSearchTermStr
                    : store.selectedClassifier.classifierImplementations[0]
                          ?.queryString ?? "",
            );
        }
    }, [
        store,
        store.selectedClassifier,
        store.selectedClassifier?.classifierImplementations,
    ]);

    return (
        <Grid container direction="column">
            <Grid item>
                <ClassifierBuilderV2Toolbar
                    store={store}
                    onSave={onSave}
                    onBack={onBack}
                    disableSave={!store.selectedClassifier}
                />
            </Grid>
            <Grid
                item
                container
                wrap="nowrap"
                direction="column"
                className={classes.content}
            >
                {store.selectedClassifier ? (
                    <>
                        <ClassifierBuilderDetails
                            name={name}
                            description={description}
                            category={category}
                            group={group}
                            onNameChange={(e) => {
                                setName(e.target.value);
                            }}
                            onDescriptionChange={(e) => {
                                setDescription(e.target.value);
                            }}
                            onCategoryChange={(cat) => {
                                if (cat.name === "No Selection") {
                                    setCategory(null);
                                } else {
                                    setCategory(cat);
                                }
                            }}
                            categoryOptions={store.classifierCategories}
                            groupOptions={store.classifierGroups}
                            onGroupChange={(group) => {
                                if (group.name === "No Selection")
                                    setGroup(undefined);
                                else setGroup(group);
                            }}
                            modifiedOn={store.selectedClassifier?.modifiedOn}
                        />
                        <ClassifierBuilderRuleset />
                    </>
                ) : store.getTaskLoading(
                      ClassifierBuilderV2Store.Tasks.UpdateClassifier,
                  ) || store.isClassifierListLoading ? (
                    <AcxLoadingIndicator size={24} alternate="PuffLoader" />
                ) : null}
            </Grid>
        </Grid>
    );
});

export default ClassifierBuilderTableV2Edit;
