import { action, computed, makeObservable, observable } from "mobx";
import { ClipTag } from "../components/Evaluation/Stores/EvalStore";
import { copyBaseFields } from "../utils/BaseEntityUtils";
import { isUndefinedType } from "../utils/TypeGuards";
import BaseEntity from "./BaseEntity";
import Evaluation from "./Evaluation";
import { InteractionType } from "./InteractionType";
import { SoundClipAnswer } from "./SoundClipAnswer";
import { StorageAccountUseOptions } from "./StorageAccount";
import type { PartialSegment } from "components/SoundClipEditor/SoundClipEditor";

export type MediaClip = SoundClip;

export default class SoundClip extends BaseEntity {
    constructor(
        id?: string,
        createdBy?: string,
        modifiedBy?: string,
        createdOn?: string,
        modifiedOn?: string,
    ) {
        super(id, createdBy, modifiedBy, createdOn, modifiedOn);
        makeObservable(this);
        this.soundClipAnswers = [];
    }

    @observable url?: string;
    @observable tags?: string;
    @observable note?: string;
    @observable startTime: number;
    @observable endTime: number;
    @observable segmentChunks?: Array<Blob>;
    @observable evaluationQbId?: number;
    @observable evaluation?: Evaluation;
    @observable evaluationId?: string;
    @observable audioMetadataId: string;
    @observable filePath?: string;
    @observable segmentName?: string;
    @observable transcriptionText?: string;
    @observable fileName?: string;
    @observable directoryId?: string;

    @observable soundClipAnswers?: SoundClipAnswer[];

    @observable segmentsRid?: number;

    @observable mediaType?: InteractionType;
    @observable _tags?: ClipTag[];

    @action setSegmentName(name: string) {
        this.segmentName = name;
    }

    @action setTranscriptionText(transcriptionText: string) {
        this.transcriptionText = transcriptionText;
    }

    @action setNote(note?: string) {
        this.note = note;
    }

    @action setName(name?: string) {
        this.segmentName = name;
    }

    @action setClipTags = (tags?: ClipTag[]) => {
        if (isUndefinedType(this._tags)) {
            this._tags = [];
        }

        if (isUndefinedType(tags)) {
            tags = [];
        }

        const existing = new Set(this._tags.map((value) => value?.id!));
        const incoming = new Set(tags.map((value) => value.id!));

        const newlyAdded = new Set(
            [...incoming].filter((x) => !existing.has(x)),
        );

        const removed = new Set([...existing].filter((x) => !incoming.has(x)));

        for (const removedId of removed) {
            for (const tg of this._tags) {
                if (tg.id === removedId) {
                    const indx = this._tags.findIndex(
                        (value) => value.id === removedId,
                    );
                    if ((indx ?? -1) > -1) {
                        this._tags.splice(indx!, 1);
                        // this triggers re-render of the computed clipTags on soundclips in Clip View
                        this._tags = [...this._tags];
                    }
                }
            }
        }

        for (const tag of tags.filter((value) => newlyAdded.has(value.id))) {
            const newtag = tag;
            this._tags = [...this._tags, newtag];
        }

        this.tags = this.clipTags.map((value) => value.displayName).join(",,");
    };

    @computed
    get clipTags(): ClipTag[] {
        return this._tags ?? [];
    }

    get clipIdentifier() {
        return `${this.segmentName}`;
    }

    @computed
    get asSegment(): PartialSegment {
        return {
            startTime: this.startTime,
            endTime: this.endTime,
            labelText: this.segmentName,
            id: this.id,
            transcriptionText: this.transcriptionText,
        };
    }

    static fromJson(clip: SoundClip) {
        const res = new SoundClip(clip.id);
        copyBaseFields(clip, res);
        res.url = clip.url;
        res.tags = clip.tags;
        res.note = clip.note;
        res.startTime = clip.startTime;
        res.endTime = clip.endTime;
        res.segmentChunks = clip.segmentChunks;
        res.evaluationQbId = clip.evaluationQbId;
        res.evaluation = clip.evaluation;
        res.evaluationId = clip.evaluationId;
        res.filePath = clip.filePath;
        res.audioMetadataId = clip.audioMetadataId;
        res.segmentName = clip.segmentName;
        res.transcriptionText = clip.transcriptionText;
        res.mediaType = clip.mediaType;
        res.segmentsRid = clip.segmentsRid;
        res.soundClipAnswers =
            clip.soundClipAnswers?.map((value) =>
                SoundClipAnswer.fromJson(value),
            ) ?? [];
        res.fileName = clip.fileName;
        res.directoryId = clip.directoryId;
        return res;
    }
}

export class ClipUIModel extends SoundClip {
    organizationId: string;
    storageAccount: string;
    storageAccountUse: StorageAccountUseOptions;
}
