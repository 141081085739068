import { useState } from "react";

const useHoverAction = (containerRef: React.RefObject<HTMLElement>) => {
    const [showAction, setShowAction] = useState(false);

    const handleBlur = () => {
        setTimeout(() => {
            if (
                !containerRef.current?.contains(document.activeElement) &&
                !containerRef.current?.matches(":hover")
            ) {
                setShowAction(false);
            }
        }, 100);
    };

    return {
        showAction,
        handlers: {
            onMouseEnter: () => setShowAction(true),
            onFocus: () => setShowAction(true),
            onBlur: handleBlur,
            onMouseLeave: () => setShowAction(false),
        },
    };
};

export default useHoverAction;
