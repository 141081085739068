import {
    Typography,
    FormControlLabel,
    Switch,
    Container,
    Box,
    FormGroup,
    IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React, { useEffect, useState } from "react";
import theme from "Theme/AppTheme";
import SourceField from "./SourceField";
import AcxButton from "components/UI/AcxButton";
import { v4 as uuidv4 } from "uuid";
import { OrganizationDataSourcesStore } from "../OrganizationDataSourcesStore";
import { observer } from "mobx-react";
import DataSourceContainer from "../DataSourceContainer";
import ConditionalLogic from "./ConditionalLogic/ConditionalLogic";
import { SourceFieldType } from "../types";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CloseIcon from "@mui/icons-material/Clear";
import ConcatFields from "./ConcatFields";

type SourceConfigurationProps = {
    store: OrganizationDataSourcesStore;
};

const SourceConfiguration: React.FC<SourceConfigurationProps> = observer(
    ({ store }) => {
        const {
            sourceFields,
            setSourceFields,
            setShouldConcatenateFields,
            setShouldApplyConditions,
            shouldApplyConditions,
            shouldConcatenateFields,
            hasSourceFieldErrors,
            checkIfSourceFieldsAreValid,
        } = store;

        const [showError, setShowError] = useState(hasSourceFieldErrors);

        useEffect(() => {
            setShowError(hasSourceFieldErrors);
        }, [hasSourceFieldErrors]);

        const handleAddField = () => {
            const updatedSourceFields = [
                ...sourceFields,
                {
                    isRequired: false,
                    path: "",
                    alias: "",
                    extractionPattern: "",
                    id: uuidv4(),
                },
            ];
            setSourceFields(updatedSourceFields);
        };

        const handleRemoveField = (id: string) => {
            const updatedSourceFields = sourceFields.filter(
                (field) => field.id !== id,
            );
            setSourceFields(updatedSourceFields);
            if (hasSourceFieldErrors) {
                checkIfSourceFieldsAreValid();
            }
        };

        const handleChange = (id: string, field: SourceFieldType) => {
            const updatedSourceFields = sourceFields.map((f) =>
                f.id === id ? field : f,
            );
            setSourceFields(updatedSourceFields);

            if (hasSourceFieldErrors) {
                checkIfSourceFieldsAreValid();
            }
        };

        return (
            <>
                <DataSourceContainer heading="Configure Source Fields">
                    {showError && (
                        <Box
                            sx={{
                                padding: theme.spacing(0, 2),
                                background: theme.palette.error[100],
                                gap: theme.spacing(2),
                                borderRadius: 2,
                                display: "flex",
                                color: theme.palette.error[700],
                                alignItems: "center",
                                marginBottom: theme.spacing(2),
                            }}
                        >
                            <InfoOutlinedIcon color="inherit" />
                            <Typography
                                sx={{
                                    color: "inherit",
                                    fontSize: "14px",
                                    fontWeight: 600,
                                }}
                            >
                                Please enter Source Field Alias and Path to
                                continue
                            </Typography>
                            <IconButton
                                sx={{ marginLeft: "auto" }}
                                color="inherit"
                                onClick={() => setShowError(false)}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    )}
                    <Box
                        sx={{
                            padding: theme.spacing(2),
                            background: theme.palette.gray[100],
                            borderRadius: 2,
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                marginBottom: theme.spacing(2),
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "14px",
                                    fontWeight: 600,
                                    marginRight: theme.spacing(2),
                                }}
                            >
                                Req
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: "14px",
                                    fontWeight: 600,
                                    marginRight: theme.spacing(1),
                                }}
                            >
                                Source Fields:
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: "10px",
                                    fontWeight: 600,
                                    paddingLeft: theme.spacing(1),
                                    paddingRight: theme.spacing(1),
                                    background: theme.palette.gray[300],
                                    borderRadius: "25px",
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                {sourceFields.length}
                            </Typography>
                        </Box>
                        <Box
                            component="ul"
                            sx={{
                                display: "flex",
                                listStyle: "none",
                                flexDirection: "column",
                                paddingInlineStart: 0,
                                gap: theme.spacing(2),
                            }}
                        >
                            {sourceFields.map((field) => (
                                <SourceField
                                    showError={hasSourceFieldErrors}
                                    key={field.id}
                                    id={field.id}
                                    path={field.path}
                                    alias={field.alias}
                                    isRequired={field.isRequired}
                                    extractionPattern={field.extractionPattern}
                                    onRemove={() => handleRemoveField(field.id)}
                                    onChange={(_field) =>
                                        handleChange(field.id, _field)
                                    }
                                />
                            ))}
                        </Box>
                        <AcxButton
                            startIcon={<AddIcon />}
                            color="white"
                            onClick={handleAddField}
                            sx={{
                                border: `1px solid ${theme.palette.lightgrayBorder.main} `,
                                background: theme.palette.white.main,
                                color: theme.palette.blue.main,
                            }}
                        >
                            Add Field
                        </AcxButton>
                    </Box>
                </DataSourceContainer>
                <Container
                    maxWidth="xl"
                    sx={{
                        "&.MuiContainer-root": {
                            padding: theme.spacing(2, 0),
                        },
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            gap: theme.spacing(3),
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 600,
                                fontSize: "14px",
                                color: theme.palette.gray[500],
                            }}
                        >
                            Advanced Options:
                        </Typography>
                        <FormGroup
                            sx={{
                                gap: theme.spacing(3),
                                flexDirection: "row",
                                ".MuiSwitch-switchBase.Mui-checked": {
                                    // reduce translate since switch is slimmer
                                    transform: "translateX(12px)",
                                },
                            }}
                        >
                            <FormControlLabel
                                sx={{ margin: 0 }}
                                control={
                                    <Switch
                                        sx={{
                                            margin: theme.spacing(0, 1, 0, 0),
                                        }}
                                        checked={shouldApplyConditions}
                                        onChange={() =>
                                            setShouldApplyConditions(
                                                !shouldApplyConditions,
                                            )
                                        }
                                    />
                                }
                                label={
                                    <Typography
                                        sx={{
                                            fontSize: "14px",
                                            color: theme.palette.gray[800],
                                        }}
                                    >
                                        Apply Conditions to Extraction
                                    </Typography>
                                }
                            />
                            <FormControlLabel
                                sx={{ margin: 0 }}
                                control={
                                    <Switch
                                        sx={{
                                            margin: theme.spacing(0, 1, 0, 0),
                                        }}
                                        checked={shouldConcatenateFields}
                                        onChange={() =>
                                            setShouldConcatenateFields(
                                                !shouldConcatenateFields,
                                            )
                                        }
                                    />
                                }
                                label={
                                    <Typography
                                        sx={{
                                            fontSize: "14px",
                                            color: theme.palette.gray[800],
                                        }}
                                    >
                                        Concatenate Fields
                                    </Typography>
                                }
                            />
                        </FormGroup>
                    </Box>
                </Container>
                {shouldApplyConditions && <ConditionalLogic store={store} />}
                {shouldConcatenateFields && <ConcatFields store={store} />}
            </>
        );
    },
);

export default SourceConfiguration;
