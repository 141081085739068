import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import AcxSelect from "components/UI/Select/BaseSelectComponents/AcxSelect";
import AcxMainTextField from "components/UI/AcxMainTextField";
import Statement from "./Statement";
import { ConditionIndicatorType } from "../../types";

import { operatorOptions } from "../../OrganizationDataSourcesStore";

type IfStatementProps = {
    fieldOptions: { label: string; value: string }[];
    indicator: ConditionIndicatorType;
    updateIndicator: (key: string, value: string) => void;
    removeIndicator: () => void;
    label?: string;
    separatorLabel?: string;
};

const IfStatement: React.FC<IfStatementProps> = observer(
    ({
        fieldOptions,
        indicator,
        updateIndicator,
        removeIndicator,
        separatorLabel,
        label,
    }) => {
        const [value, setValue] = useState(indicator.value);

        useEffect(() => {
            // debounce the value changing since it rerenders the array
            const handler = setTimeout(() => {
                updateIndicator("value", value);
            }, 300);

            return () => {
                clearTimeout(handler);
            };

            // No need to run this function when updateIndicator changes
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [value]);

        return (
            <Statement
                remove={removeIndicator}
                separatorLabel={separatorLabel}
                label={label}
            >
                <AcxSelect
                    id={`statement-value-path-${indicator.id}`}
                    placeholder="Select field"
                    options={fieldOptions}
                    // defaultValue={indicator.valuePath} TODO - figure out why default values require selecting twice
                    onChange={(e) => updateIndicator("valuePath", e.value)}
                />
                <AcxSelect
                    id={`statement-compare-type-${indicator.id}`}
                    placeholder="Select operator"
                    options={operatorOptions}
                    // defaultValue={indicator.compareType} TODO - figure out why default values require selecting twice
                    onChange={(e) => updateIndicator("compareType", e.value)}
                />
                <AcxMainTextField
                    containerStyle={{ width: "100%" }}
                    id={`statement-value-${indicator.id}`}
                    fullWidth
                    placeholderText="Enter value"
                    isDisabled={indicator.compareType === 0}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                />
            </Statement>
        );
    },
);

export default IfStatement;
