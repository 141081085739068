import { Box, Grid, SxProps } from "@mui/material";
import React, { useRef } from "react";
import theme from "Theme/AppTheme";
import { observer } from "mobx-react";
import useHoverAction from "../../useHoverAction";
import DeleteButton from "../../DeleteButton";

type StatementProps = {
    remove: () => void;
    label?: string;
    separatorLabel?: string;
    children?: React.ReactNode;
    sx?: SxProps;
};

const Statement: React.FC<StatementProps> = observer(
    ({ remove, label, separatorLabel, children, sx }) => {
        const containerRef = useRef<HTMLDivElement>(null);
        const { showAction, handlers } = useHoverAction(containerRef);

        return (
            <Grid
                container
                sx={{
                    display: "flex",
                    alignItems: "center",
                    ...sx,
                }}
            >
                {label && (
                    <Grid
                        item
                        sx={{
                            padding: theme.spacing(1),
                            color: theme.palette.primary.dark,
                            background: theme.palette.primary.light,
                            height: "fit-content",
                            fontWeight: 600,
                        }}
                    >
                        {label}
                    </Grid>
                )}
                <Grid
                    item
                    ref={containerRef}
                    {...handlers}
                    xs={11}
                    xl={10}
                    sx={{
                        display: "flex",
                        gap: theme.spacing(2),
                        padding: theme.spacing(1, 0),
                        borderRadius: 2,
                    }}
                >
                    {children}
                    <DeleteButton
                        showDelete={showAction}
                        onDelete={remove}
                        sx={{ margin: theme.spacing(1, -1) }}
                    />
                </Grid>
                {separatorLabel && (
                    <Grid xs={12} item>
                        <Box
                            sx={{
                                padding: theme.spacing(1),
                                color: theme.palette.primary.dark,
                                background: theme.palette.primary.light,
                                fontWeight: 600,
                                borderRadius: 2,
                                width: "fit-content",
                            }}
                        >
                            {separatorLabel}
                        </Box>
                    </Grid>
                )}
            </Grid>
        );
    },
);

export default Statement;
