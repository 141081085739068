import { Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React, { useRef } from "react";
import theme from "Theme/AppTheme";
import AcxButton from "components/UI/AcxButton";
import { observer } from "mobx-react";

import ResultStatement from "./ResultStatement";
import IfStatement from "./IFStatement";
import useHoverAction from "../../useHoverAction";
import { StatementType } from "../../types";
import DeleteButton from "../../DeleteButton";

type StatementHandlerProps = {
    statement: StatementType;
    fieldsOptions: { label: string; value: string }[];
    elseStatement?: StatementType;
    addThenConditionIndicator: () => void;
    removeThenIndicator: (indicatorId: string) => void;
    updateThenIndicator: (
        indicatorIndex: number,
        key: string,
        value: string | number,
    ) => void;
    addResult: () => void;
    removeResult: () => void;
    updateResult: (value: string) => void;
    addElse: () => void;
    removeElseResult: () => void;
    updateElseResult: (value: string) => void;
    addChildStatement: () => void;
    removeSelf?: () => void;
    isLastIndex?: boolean;
    isMaxLength?: boolean;
    isElseStatement?: boolean;
};

const StatementHandler: React.FC<StatementHandlerProps> = observer(
    ({
        statement,
        fieldsOptions,
        elseStatement,
        addThenConditionIndicator,
        removeThenIndicator,
        updateThenIndicator,
        addResult,
        removeResult,
        updateResult,
        addElse,
        removeElseResult,
        updateElseResult,
        addChildStatement,
        removeSelf,
        isLastIndex,
        isMaxLength,
        isElseStatement,
    }) => {
        const containerRef = useRef<HTMLDivElement>(null);
        const { showAction, handlers } = useHoverAction(containerRef);

        const hasResult = typeof statement.result !== "undefined";
        const hasElseStatement = typeof elseStatement?.result !== "undefined";
        const hasChildStatements = elseStatement?.childStatements?.length || 0;

        const showElseButtons =
            hasResult &&
            !hasElseStatement &&
            !hasChildStatements &&
            !isMaxLength;

        return (
            <Box
                ref={containerRef}
                {...handlers}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    position: "relative",
                    gap: theme.spacing(2),
                    padding: theme.spacing(2),
                    width: "100%",
                    border: isElseStatement
                        ? undefined
                        : `1px solid ${theme.palette.gray[200]}`,
                    borderRadius: 2,
                }}
            >
                {isElseStatement && (
                    <Box
                        sx={{
                            padding: theme.spacing(1),
                            margin: theme.spacing(0, 0, 2, -2),
                            color: theme.palette.primary.dark,
                            background: theme.palette.primary.light,
                            height: "fit-content",
                            width: "fit-content",
                            fontWeight: 600,
                        }}
                    >
                        ELSE
                    </Box>
                )}
                {!!statement.conditionIndicators?.length && (
                    <Box
                        sx={{
                            display: "flex",
                            width: "100%",
                            maxWidth: `calc(100% - ${theme.spacing(2)})`,
                            gap: theme.spacing(2),
                        }}
                    >
                        <Box
                            sx={{
                                padding: theme.spacing(1),
                                color: theme.palette.primary.dark,
                                background: theme.palette.primary.light,
                                margin: theme.spacing(2),
                                height: "fit-content",
                                width: "fit-content",
                                fontWeight: 600,
                            }}
                        >
                            IF
                        </Box>

                        <Box
                            sx={{
                                width: "100%",
                                backgroundColor: theme.palette.gray[100],
                                padding: theme.spacing(2),
                                borderRadius: 2,
                            }}
                        >
                            {statement?.conditionIndicators?.map(
                                (indicator, indicatorIndex) => {
                                    const showSeparator =
                                        indicatorIndex !==
                                        (statement.conditionIndicators
                                            ?.length || 1) -
                                            1;
                                    return (
                                        <IfStatement
                                            separatorLabel={
                                                showSeparator ? "OR" : ""
                                            }
                                            removeIndicator={() => {
                                                removeThenIndicator(
                                                    indicator.id,
                                                );
                                            }}
                                            key={indicator.id}
                                            indicator={indicator}
                                            updateIndicator={(key, value) => {
                                                updateThenIndicator(
                                                    indicatorIndex,
                                                    key,
                                                    value,
                                                );
                                            }}
                                            fieldOptions={fieldsOptions}
                                        />
                                    );
                                },
                            )}
                            <AcxButton
                                startIcon={<AddIcon />}
                                onClick={() => {
                                    addThenConditionIndicator();
                                }}
                                color="white"
                                sx={{
                                    color: theme.palette.primary.main,
                                }}
                            >
                                OR
                            </AcxButton>
                        </Box>
                    </Box>
                )}
                {hasResult && (
                    <ResultStatement
                        id={statement.id}
                        removeResult={removeResult}
                        result={statement.result || ""} // for linter
                        updateResult={updateResult}
                    />
                )}
                {hasElseStatement && (
                    <>
                        <Box
                            sx={{
                                padding: theme.spacing(1),
                                color: theme.palette.primary.dark,
                                background: theme.palette.primary.light,
                                fontWeight: 600,
                                borderRadius: 2,
                                width: "fit-content",
                                marginLeft: theme.spacing(2),
                            }}
                        >
                            ELSE
                        </Box>
                        <ResultStatement
                            id={elseStatement.id}
                            removeResult={removeElseResult}
                            result={elseStatement.result || ""} // for linter
                            updateResult={updateElseResult}
                        />
                    </>
                )}
                <Box sx={{ display: "flex", gap: theme.spacing(1) }}>
                    {!hasResult && (
                        <>
                            {!statement?.conditionIndicators?.length && (
                                <AcxButton
                                    startIcon={<AddIcon />}
                                    onClick={() => {
                                        addThenConditionIndicator();
                                    }}
                                    color="white"
                                    sx={{
                                        color: theme.palette.primary.main,
                                    }}
                                >
                                    IF
                                </AcxButton>
                            )}

                            <AcxButton
                                startIcon={<AddIcon />}
                                onClick={() => {
                                    addResult();
                                }}
                                color="white"
                                sx={{
                                    color: theme.palette.primary.main,
                                }}
                            >
                                RESULT
                            </AcxButton>
                        </>
                    )}
                    {showElseButtons && (
                        <>
                            <AcxButton
                                startIcon={<AddIcon />}
                                onClick={() => addElse()}
                                color="white"
                                sx={{
                                    color: theme.palette.primary.main,
                                }}
                            >
                                ELSE
                            </AcxButton>

                            <AcxButton
                                startIcon={<AddIcon />}
                                color="white"
                                sx={{
                                    color: theme.palette.primary.main,
                                }}
                                onClick={() => addChildStatement()}
                            >
                                ELSE IF
                            </AcxButton>
                        </>
                    )}
                </Box>
                {removeSelf && (
                    <DeleteButton
                        onDelete={removeSelf}
                        showDelete={showAction}
                        sx={{
                            position: "absolute",
                            top: theme.spacing(1),
                            right: theme.spacing(1),
                        }}
                    />
                )}
            </Box>
        );
    },
);

export default StatementHandler;
