import { Grid, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import AcxWideTooltip from "components/UI/AcxWideTooltip";
import _ from "lodash";
import { observer } from "mobx-react";
import moment from "moment";
import React, { FunctionComponent, useEffect } from "react";
import sanitizeHtml from "sanitize-html";
import AcxMainTextField from "components/UI/AcxMainTextField";
import AcxCalendarPopup from "components/UI/Calendar/AcxCalendarPopup";
import AcxSelectMulti from "components/UI/Select/BaseSelectComponents/AcxSelectMulti";
import AcxSelectSingle from "components/UI/Select/BaseSelectComponents/AcxSelectSingle";
import { Answer } from "models/Answer";
import { AnswerTypeVariations, AnswerTypeNames } from "models/AnswerType";
import Question, { QuestionType } from "models/Question";
import { Tag } from "models/Tag";
import InfoSvg from "SvgIcons/InfoSvg";
import theme from "Theme/AppTheme";
import ConvoNote from "./ConvoNote";
import { ConvoModuleUIModel } from "./ConvoModuleUIModel";
import ConvoClip from "./ConvoClip";
import ConvoMultiTagClipWrapper from "./ConvoMultiTagClipWrapper";

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    padding3: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    questionText: (props: Props) => ({
        color: "#1F1F1F",
        fontSize: "14px",
        fontWeight: "bold",
        letterSpacing: "0",
        lineHeight: "20px",
    }),
    fullSize: {
        height: "100%",
        width: "100%",
    },
    questionHeader: (props: Props) => ({
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(2),
        paddingRight: theme.spacing(3.5),
    }),
    infoSVG: {
        marginRight: theme.spacing(2.7),
        paddingTop: theme.spacing(1),
        marginBottom: theme.spacing(0.5),
    },
    sPContainer: {
        display: "flex",
        flexDirection: "row",
        wrap: "nowrap",
        alignItems: "center",
    },
}));

interface OwnProps {
    question: Question;
    moduleUIModel?: ConvoModuleUIModel;
    leftBorderColor?: string;
    isDisabled?: boolean;
    getAnswerForQuestion: (
        question: Question,
        conversationModuleId: string | undefined,
    ) => Answer | undefined;
    setAnswerForQuestion: (
        question: Question,
        tags: Tag[],
        answer: Answer | undefined,
        conversationModuleId?: string,
    ) => Answer;
    setFillInAnswerForQuestion: (
        question: Question,
        fillInAnswer: string,
        answer: Answer | undefined,
        conversationModuleId?: string,
    ) => Answer;
    showClips?: boolean;
}

type Props = OwnProps;

const selectCustomStyle = {
    menuPortal: (provided, state) => ({ ...provided, zIndex: 9999 }),
};

const ConvoTOFIResponseAnswer: FunctionComponent<Props> = observer((props) => {
    const classes = useStyles(props);

    const answer: Answer | undefined = props.getAnswerForQuestion(
        props.question,
        props.moduleUIModel?.conversationModuleId,
    );

    let activeTags: Tag | Tag[] | undefined = answer?.activeTags;

    if (props.question.answerType.variation === AnswerTypeVariations.Single) {
        activeTags =
            (activeTags?.length ?? 0) > 0 ? activeTags?.[0] : undefined;
    }

    useEffect(() => {
        if (Array.isArray(activeTags)) {
            props.moduleUIModel?.updateModuleScore(props.question, activeTags);
        } else {
            props.moduleUIModel?.updateModuleScore(
                props.question,
                activeTags ? [activeTags] : [],
            );
        }
    }, [props.question, props.moduleUIModel, activeTags]);

    const showClipMultiClip: boolean =
        props.question.answerType.variation !== AnswerTypeVariations.Single &&
        (props.question.answerType.answerTypeName === "Tag Response" ||
            props.question.answerType.answerTypeName === "Scored Tag Response");

    const showNotesAndClips = !!answer && !!answer.isAnswered;

    return (
        <Grid
            id={`question-${props.question.id}`}
            item
            xs={12}
            style={{
                backgroundColor: "unset",
                paddingLeft:
                    props.question.parentId || props.leftBorderColor
                        ? "16px"
                        : "0px",
                borderLeft: props.leftBorderColor
                    ? `2px solid ${props.leftBorderColor}`
                    : "none",
            }}
            container
            direction={"column"}
            justifyContent={"flex-start"}
            alignItems="stretch"
        >
            <Grid
                item
                xs={12}
                container
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                wrap={"nowrap"}
                className={clsx(classes.questionHeader, {
                    [classes.padding3]: true,
                })}
            >
                <AcxWideTooltip
                    title={
                        sanitizeHtml(props.question.helperText) ? (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: sanitizeHtml(
                                        props.question.helperText,
                                        {
                                            allowedAttributes: {
                                                table: ["style"],
                                                th: ["style"],
                                                td: ["style"],
                                            },
                                            allowedStyles: {
                                                "*": {
                                                    // Match any number with px, em, or %
                                                    width: [/^\d+(?:px|em|%)$/],
                                                    border: [
                                                        /^1px solid rgba\(0, 0, 0, 1\)$/,
                                                    ],
                                                },
                                            },
                                        },
                                    ),
                                }}
                            />
                        ) : (
                            ""
                        )
                    }
                    placement={"top"}
                >
                    <Grid item className={classes.infoSVG}>
                        <InfoSvg width="24px" height="24px" />
                    </Grid>
                </AcxWideTooltip>
                <Grid item>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={1}
                    >
                        <Grid item>
                            <Typography className={classes.questionText}>
                                {props.question?.questionText}{" "}
                                {props.question.required &&
                                    !answer?.isAnswered && (
                                        <span
                                            style={{
                                                color: theme.palette.error.main,
                                            }}
                                        >
                                            *
                                        </span>
                                    )}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {props.question.type !== QuestionType.QuestionHeading && (
                <Grid
                    item
                    xs={12}
                    direction={"row"}
                    wrap={"nowrap"}
                    container
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    className={clsx({
                        [classes.padding3]: true,
                    })}
                >
                    <Grid item xs={12} style={{ marginBottom: "8px" }}>
                        <AnswerSelectorComponent
                            answer={answer}
                            // store={store}
                            activeTags={activeTags}
                            question={props.question}
                            leftBorderColor={props.leftBorderColor}
                            moduleUIModel={props.moduleUIModel}
                            isDisabled={props.isDisabled}
                            setAnswerForQuestion={props.setAnswerForQuestion}
                            getAnswerForQuestion={props.getAnswerForQuestion}
                            setFillInAnswerForQuestion={
                                props.setFillInAnswerForQuestion
                            }
                        />
                    </Grid>
                </Grid>
            )}
            {showNotesAndClips && (
                <>
                    <ConvoNote
                        question={props.question}
                        answer={answer}
                        columnDirection={"column-reverse"}
                        isDisabled={props.isDisabled}
                        conversationModuleId={
                            props.moduleUIModel?.conversationModuleId
                        }
                    />
                    {props.showClips &&
                        (showClipMultiClip ? (
                            <ConvoMultiTagClipWrapper
                                question={props.question}
                                answer={answer}
                                isDisabled={!answer}
                            />
                        ) : (
                            <ConvoClip
                                question={props.question}
                                answer={answer}
                                isDisabled={!answer}
                            />
                        ))}
                </>
            )}
        </Grid>
    );
});

const AnswerSelectorComponent = observer(
    (
        props: OwnProps & {
            children?: React.ReactNode;
            answer: Answer | undefined;
            activeTags: Tag | Tag[] | undefined;
        },
    ) => {
        const conversationModuleId = props.moduleUIModel?.conversationModuleId;

        function onFillInAnswerSelect(fillInAnswer: string) {
            props.setFillInAnswerForQuestion(
                props.question,
                fillInAnswer,
                props.answer,
                conversationModuleId,
            );
        }

        if (
            props.question.answerType.variation === AnswerTypeVariations.Single
        ) {
            function onSingleAnswerSelect(arg?: Tag) {
                props.setAnswerForQuestion(
                    props.question,
                    arg ? [arg] : [],
                    props.answer,
                    conversationModuleId,
                );
            }

            return (
                <AcxSelectSingle
                    options={
                        props.moduleUIModel?.getTagsFormattedForTagResponse(
                            props.question.id,
                        ) ?? []
                    }
                    containerHeight={"auto"}
                    customStyle={selectCustomStyle}
                    menuPlacement={"auto"}
                    menuPortalTarget={document.body}
                    id={`tag-response-${props.question.id}`}
                    fullWidth
                    colorField={
                        props.moduleUIModel?.moduleTypeName === "Interaction"
                            ? undefined
                            : "sentimentColor"
                    }
                    defaultValue={props.activeTags as any}
                    valueField={"id"}
                    labelField={"value"}
                    isClearable
                    onChange={onSingleAnswerSelect}
                    isDisabled={props.isDisabled}
                />
            );
        } else if (
            props.question.answerType.answerTypeName ===
            AnswerTypeNames.DateResponse
        ) {
            const currentDate = props.answer?.fillInAnswerValue
                ? moment(props.answer.fillInAnswerValue)
                : undefined;

            return (
                <AcxCalendarPopup
                    id={`fill-in-response-${props.question.id}`}
                    onSelect={(date) =>
                        onFillInAnswerSelect(date.format("YYYY-MM-DD"))
                    }
                    currentDate={currentDate}
                    disabled={props.isDisabled}
                />
            );
        } else if (
            props.question.answerType.answerTypeName ===
            AnswerTypeNames.TextResponse
        ) {
            const value = props.answer?.fillInAnswerValue
                ? props.answer.fillInAnswerValue
                : undefined;

            // eslint-disable-next-line react-hooks/exhaustive-deps
            const debounceOnChange = React.useCallback(
                _.debounce((fillInValue) => {
                    onFillInAnswerSelect(fillInValue);
                }, 500),
                [],
            );

            return (
                <AcxMainTextField
                    id={`fill-in-response-${props.question.id}`}
                    onChange={(evt) => debounceOnChange(evt.target.value)}
                    multiline
                    rows={3}
                    value={value}
                    maxLength={props.question.maxLength}
                    isDisabled={props.isDisabled}
                    textareaRootStyles={{
                        paddingLeft: theme.spacing(1),
                        paddingRight: theme.spacing(1),
                        paddingBlock: theme.spacing(1),
                    }}
                />
            );
        } else {
            function onAnswerSelect(arg?: Tag[]) {
                props.setAnswerForQuestion(
                    props.question,
                    arg ?? [],
                    props.answer,
                    conversationModuleId,
                );
            }

            return (
                <AcxSelectMulti
                    options={
                        props.moduleUIModel?.getTagsFormattedForTagResponse(
                            props.question.id,
                        ) ?? []
                    }
                    inputLabel={"Add tag(s)"}
                    containerHeight={"auto"}
                    customStyle={selectCustomStyle}
                    menuPlacement={"auto"}
                    menuPortalTarget={document.body}
                    id={`tag-response-${props.question.id}`}
                    fullWidth
                    colorField={"sentimentColor"}
                    defaultValue={props.activeTags as any}
                    valueField={"id"}
                    labelField={"value"}
                    isClearable
                    onChange={onAnswerSelect}
                    isDisabled={props.isDisabled}
                />
            );
        }
    },
);

export default ConvoTOFIResponseAnswer;
