import { Grid, IconButton, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import theme from "Theme/AppTheme";
import { observer } from "mobx-react";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import useHoverAction from "../../useHoverAction";
import AcxSelect from "components/UI/Select/BaseSelectComponents/AcxSelect";
import AcxMainTextField from "components/UI/AcxMainTextFieldGrid";

type ConditionHeaderProps = {
    ruleId: string;
    name: string;
    updateTargetField: (ruleId: string, value: number) => void;
    updateConditionName: (ruleId: string, value: string) => void;
    targetMetaDataField: number;
    targetFields: { label: string; value: number }[];
};

const ConditionHeader: React.FC<ConditionHeaderProps> = observer(
    ({
        updateTargetField,
        targetMetaDataField,
        targetFields,
        ruleId,
        updateConditionName,
        name,
    }) => {
        const containerRef = useRef<HTMLDivElement>(null);
        const { showAction, handlers } = useHoverAction(containerRef);
        const [isEditing, setIsEditing] = useState(false);
        const [value, setValue] = useState(name);

        return (
            <Grid
                item
                container
                xs={12}
                sx={{
                    borderBottom: `1px solid ${theme.palette.gray[200]}`,
                    padding: theme.spacing(2),
                }}
            >
                <Grid
                    item
                    xs={12}
                    sx={{
                        display: "flex",
                        gap: theme.spacing(1),
                        alignItems: "center",
                        marginBottom: theme.spacing(1),
                    }}
                    ref={containerRef}
                    {...handlers}
                >
                    {isEditing ? (
                        <AcxMainTextField
                            id={`statement-value-${ruleId}`}
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                            containerSx={{
                                width: "fit-content",
                            }}
                        />
                    ) : (
                        <Typography
                            variant="h3"
                            sx={{
                                fontSize: "16px",
                                fontWeight: 600,
                                padding: theme.spacing(1, 0),
                            }}
                        >
                            {name}
                        </Typography>
                    )}
                    <IconButton
                        size="small"
                        sx={{
                            padding: theme.spacing(0.25),
                            height: "fit-content",
                            visibility: showAction ? "visible" : "hidden",
                        }}
                        onClick={() => {
                            if (isEditing) {
                                updateConditionName(ruleId, value);
                            }
                            setIsEditing(!isEditing);
                        }}
                    >
                        {isEditing ? (
                            <CheckIcon fontSize="inherit" />
                        ) : (
                            <EditIcon fontSize="inherit" />
                        )}
                    </IconButton>
                </Grid>
                <Grid
                    item
                    xs={10}
                    sx={{
                        background: theme.palette.gray[100],
                        display: "flex",
                        gap: theme.spacing(1),
                        padding: theme.spacing(1),
                        borderRadius: 2,
                        alignItems: "center",
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: 600,
                            padding: theme.spacing(1),
                        }}
                    >
                        Persist to
                    </Typography>
                    <AcxSelect
                        // defaultValue={targetMetaDataField} TODO - understand why setting the default value breaks changing values
                        id={`condition-rule-${targetMetaDataField}-id`}
                        placeholder="Select Target Field"
                        options={targetFields}
                        onChange={(e) => {
                            updateTargetField(ruleId, e.value);
                        }}
                    />
                </Grid>
            </Grid>
        );
    },
);

export default ConditionHeader;
