import { Grid } from "@mui/material";
import AcxSelectMulti from "components/UI/Select/BaseSelectComponents/AcxSelectMulti";
import { observer } from "mobx-react";
import { Answer } from "models/Answer";
import Question from "models/Question";
import SoundClip from "models/SoundClip";
import React, { FunctionComponent, useCallback, useEffect } from "react";
import { useStore } from "utils/useStore";
import ConversationLabelingStore from "../store/ConversationLabelingStore";
import ConversationsStore from "components/Conversations/Stores/ConversationsStore";
import TurnRightRoundedIcon from "@mui/icons-material/TurnRightRounded";
import { Tag } from "models/Tag";
import AcxSelect from "components/UI/Select/BaseSelectComponents/AcxSelect";
import { parseStringTags } from "utils/soundClipStringTagHelper";
import theme from "Theme/AppTheme";

interface OwnProps {
    answer?: Answer;
    question: Question;

    conversationModuleId?: string;
    isDisabled?: boolean;
    clipIndex: number;
    removeClip?: (index: number) => void;
}

type Props = OwnProps;

const selectCustomStyle = {
    menuPortal: (provided, state) => ({ ...provided, zIndex: 9999 }),
};

const ConvoMultiTagClip: FunctionComponent<Props> = observer((props) => {
    const store = useStore(ConversationLabelingStore);
    const convoStore = useStore(ConversationsStore);

    const setClipForAnswer = useCallback(
        (soundClip: SoundClip | undefined) => {
            if (!props.answer) {
                // answer should always exist, cant add clips if no answer
                return;
            }

            const clipAtIndex = props.answer.activeSoundClips[props.clipIndex];
            if (!soundClip) {
                clipAtIndex.setClipTags();
                props.answer.activeSoundClips.splice(props.clipIndex, 1);
                props.answer.setSoundClips(props.answer.activeSoundClips);
            } else {
                if (!clipAtIndex) {
                    props.answer.setSoundClips([
                        ...props.answer.activeSoundClips,
                        soundClip,
                    ]);
                } else {
                    const oldClips = props.answer.activeSoundClips.filter(
                        (sc) => sc.id !== clipAtIndex.id,
                    );
                    props.answer.setSoundClips([...oldClips, soundClip]);
                }
            }
        },
        [props.answer, props.clipIndex],
    );

    useEffect(() => {
        props.answer?.soundClipAnswers
            .map((sca) => sca.soundClip)
            .forEach((value) => {
                if (!value) return;
                parseStringTags(props.question?.tags ?? [], value);
            });
        // only want to rerun this on answer change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.answer]);

    const setTagForClip = useCallback(
        (arg: Tag[] | undefined) => {
            const currentSoundClip =
                props.answer?.soundClipAnswers[props.clipIndex].soundClip;

            currentSoundClip?.setClipTags(arg);
        },
        // only want to update this when clips are added/removed
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.answer?.soundClipAnswers?.length],
    );

    const tagDefaultValue =
        props.answer?.activeSoundClips?.[props.clipIndex]?._tags;

    return (
        <Grid item container xs={12} paddingBottom={theme.spacing(1)}>
            <Grid
                container
                item
                xs={12}
                justifyContent={"flex-start"}
                alignItems={"stretch"}
                key={props.clipIndex}
                paddingLeft={theme.spacing(3)}
                paddingRight={theme.spacing(3)}
            >
                <Grid container item xs={12} paddingBottom={theme.spacing(1)}>
                    <AcxSelect
                        options={
                            convoStore.activeSoundClipsNotAttachedToAnswer ?? []
                        }
                        containerHeight={"auto"}
                        customStyle={selectCustomStyle}
                        menuPlacement={"auto"}
                        menuPortalTarget={document.body}
                        defaultValue={[
                            props.answer?.activeSoundClips[props.clipIndex],
                        ]}
                        isClearable
                        fullWidth
                        inputLabel={"Add Clip"}
                        onChange={setClipForAnswer}
                        id={"QuoteSelectorForModuleAnswer" + props.question.id}
                        valueField={"segmentName"}
                        labelField={"segmentName"}
                        isDisabled={props.isDisabled}
                        placeholder="Select Clip"
                    />
                </Grid>
                <Grid container item xs={12}>
                    <Grid
                        item
                        container
                        xs={2}
                        justifyContent={"center"}
                        alignItems={"center"}
                    >
                        <TurnRightRoundedIcon
                            style={{
                                transform: "rotateX(180deg)",
                            }}
                            color={"disabled"}
                        />
                    </Grid>
                    <Grid item xs={10}>
                        <AcxSelectMulti
                            options={
                                store.uiModel.getTagsFormattedForTagResponse(
                                    props.question?.id,
                                ) ?? []
                            }
                            colorField={"sentimentColor"}
                            containerHeight={"auto"}
                            customStyle={selectCustomStyle}
                            menuPlacement={"auto"}
                            menuPortalTarget={document.body}
                            defaultValue={tagDefaultValue}
                            isClearable
                            fullWidth
                            onChange={setTagForClip}
                            id={
                                "QuoteSelectorForModuleAnswer" +
                                props.question.id
                            }
                            valueField={"id"}
                            labelField={"value"}
                            isDisabled={
                                !props.answer?.activeSoundClips[props.clipIndex]
                            }
                            placeholder="Select Tag"
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
});

export default ConvoMultiTagClip;
