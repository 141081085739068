import { ClassifierBuilderV2Store } from "../../Stores/ClassifierBuilderV2Store";
import { useStore } from "utils/useStore";
import { Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Theme } from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import ClassifierBuilderDetails from "./ClassifierBuilderV2Details";
import ClassifierBuilderRuleset from "./ClassifierBuilderV2Ruleset";
import { AuthStore } from "stores/AuthStore";
import ClassifierBuilderV2Toolbar from "./ClassifierBuilderV2Toolbar";
import ClassifierGroup from "models/ClassifierGroup";
import { AsyncTaskStatus } from "stores/BaseStore";

const useStyles = makeStyles((theme: Theme) => ({
    content: {
        paddingLeft: theme.spacing(20),
        paddingRight: theme.spacing(20),
        paddingTop: theme.spacing(3),
        height: "calc(100% - 64px)",
    },
}));

const ClassifierBuilderTableV2Create = observer(() => {
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const store = useStore(ClassifierBuilderV2Store);
    const authStore = useStore(AuthStore);
    const orgStore = authStore.orgStore;
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [category, setCategory] = useState(null);
    const [group, setGroup] = useState<ClassifierGroup>();

    const onBack = () => {
        if (location.key !== "default") {
            navigate("../");
        } else {
            navigate(-1);
        }
    };

    const onSave = async () => {
        try {
            store.setTestClassifierError("");

            let isTestValid = true;

            isTestValid = await store.validateRbcFtsSearchString();

            if (store.testClassifierError !== "" || !isTestValid) return;

            const result = await store.createClassifier(
                name,
                store.rbcSearchTermStr,
                description,
                category,
                group,
            );

            if (result !== AsyncTaskStatus.Error)
                return navigate(`../edit/${result.classifierId}`);
        } catch (error) {
            throw error;
        }
    };

    const onCategoryChange = (cat) => {
        if (cat.name === "No Selection") {
            setCategory(null);
        } else {
            setCategory(cat);
        }
    };

    const onGroupChange = (group) => {
        if (group.name === "No Selection") {
            setGroup(undefined);
        } else {
            setGroup(group);
        }
    };

    const onDescriptionChange = (e) => {
        setDescription(e.target.value);
    };

    const onNameChange = (e) => {
        setName(e.target.value);
    };

    useEffect(() => {
        if (orgStore && orgStore.selectedOrganization) {
            store.orgSelectStore.selectOrg(orgStore.selectedOrganization);
            store.setRbcSearchTermStr(store?.rbcSearchTermStr ?? "");
        }
    }, [orgStore, orgStore?.selectedOrganization, store]);

    return (
        <Grid container direction="column">
            <Grid item>
                <ClassifierBuilderV2Toolbar
                    store={store}
                    onBack={onBack}
                    onSave={onSave}
                    disableSave={!name || !store.rbcSearchTermStr}
                    disableCopy
                />
            </Grid>
            <Grid item container direction="column" className={classes.content}>
                <ClassifierBuilderDetails
                    name={name}
                    description={description}
                    category={category}
                    onNameChange={onNameChange}
                    onDescriptionChange={onDescriptionChange}
                    onCategoryChange={onCategoryChange}
                    categoryOptions={store.classifierCategories}
                    group={group}
                    groupOptions={store.classifierGroups}
                    onGroupChange={onGroupChange}
                    modifiedOn={store.selectedClassifier?.modifiedOn}
                />
                <ClassifierBuilderRuleset />
            </Grid>
        </Grid>
    );
});

export default ClassifierBuilderTableV2Create;
