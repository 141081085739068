import {
    Grid,
    Step,
    Stepper,
    Button,
    styled,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";

import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

import clsx from "clsx";
import AcxButton from "components/UI/AcxButton";
import AcxDataGrid from "components/UI/AcxDataGrid/AcxDataGrid";
import IColDef from "components/UI/AcxDataGrid/IColDef";
import AcxMainTextField from "components/UI/AcxMainTextField";
import AcxDialog from "components/UI/Dialog/AcxDialog";
import AcxSelect from "components/UI/Select/BaseSelectComponents/AcxSelect";
import { observer } from "mobx-react";
import { OrganizationMetadataFileType } from "models/OrganizationModels/OrganizationMetadataFileType";
import { OrganizationMetadataFileTypeExtension } from "models/OrganizationModels/OrganizationMetadataFileTypeExtension";
import React, { useEffect, useMemo, useState } from "react";
import { parseFromISO, standardDateFormat } from "utils/DateTimeUtils";
import { useStore } from "utils/useStore";
import { OrganizationDataSourcesStore } from "./OrganizationDataSourcesStore";
import DataSourceSetup from "./DataSourceSetup";
import DataSourceConfiguration from "./SourceConfiguration/SourceConfiguration";

const StyledStep = styled(Step)(({ theme }) => ({
    minWidth: "132px",
    height: "38px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "0",
    padding: theme.spacing(0, 1),

    // Define classes for conditional styling
    ".MuiButtonBase-root": {
        padding: theme.spacing(0, 3, 0, 3),
        left: theme.spacing(1.5),
        height: "100%",
        width: "100%",
        color: theme.palette.gray[500],
        fontSize: "14px",
        fontWeight: 500,
    },

    "&.active": {
        backgroundColor: "white",
        position: "relative",
        color: theme.palette.neutral.dark,

        ".MuiButtonBase-root": {
            color: theme.palette.neutral.dark,
            fontWeight: 600,
        },

        // right arrow
        ":not(.last)&::after": {
            content: '""',
            position: "absolute",
            right: "-15px",
            borderLeft: "15px solid transparent",
            borderRight: "15px solid white",
            borderTop: "15px solid white",
            borderBottom: "15px solid transparent",
            borderRadius: "15%",
            transform: "rotate(45deg)",
        },

        // left arrow
        ":not(.first)&::before": {
            content: '""',
            position: "absolute",
            left: "-15px",
            borderLeft: "15px solid transparent",
            borderRight: `15px solid ${theme.palette.gray[200]}`,
            borderTop: `15px solid ${theme.palette.gray[200]}`,
            borderBottom: "15px solid transparent",
            borderRadius: "15%",
            transform: "rotate(45deg)",
        },
    },
    "&.inactive": {
        backgroundColor: theme.palette.gray[200],
        color: theme.palette.gray[500],
    },
    "&.disabled": {
        backgroundColor: "#ECEDF0",
    },
    "&.first": {
        borderRadius: "19px 0 0 19px",
    },
    "&.last": {
        borderRadius: "0 19px 19px 0",
        paddingRight: theme.spacing(4),
    },
}));

const steps = [
    "Data Source",
    "Setup",
    "Source Configuration",
    "Target Mapping",
    "Value Mapping",
];

const metadataSourceOptions = [
    { label: "JSON", value: "JSON" },
    { label: "XML", value: "XML" },
    { label: "CSV", value: "CSV" },
    { label: "FileName", value: "FileName" },
    { label: "GenesysCloud", value: "GenesysCloud" },
    { label: "Vonage", value: "Vonage" },
    { label: "NiceCxOne", value: "NiceCxOne" },
    { label: "Five9 Voice Stream", value: "Five9VoiceStream" },
];

type Props = {
    id: string;
};

const OrganizationDataSources: React.FC<Props> = observer(({ id }) => {
    const theme = useTheme();
    const organizationDataSourcesStore = useStore(OrganizationDataSourcesStore);
    const {
        activeStep,
        setActiveStep,
        setSelectedDatasource,
        datasourcesDgStore,
        loadDataSources,
        selectedDatasource,
        checkIfSourceFieldsAreValid,
    } = organizationDataSourcesStore;
    const [showAddDataSourceModal, setShowAddDataSourceModal] = useState(false);

    const isNotSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));

    // Define columns with responsive widths
    const columns: IColDef[] = useMemo(
        () => [
            {
                headerName: "Configuration Name",
                field: "fullName",
                width: isNotSmallScreen ? 200 : 700,
                valueGetter: (_, row) => row.configuration.name,
                renderCell: (params) => (
                    <AcxButton
                        variant="text"
                        onClick={() => {
                            setSelectedDatasource(params.row.configuration);
                            setActiveStep(1);
                        }}
                    >
                        {params.row.configuration.name}
                    </AcxButton>
                ),
            },
            {
                headerName: "Source",
                field: "firstName",
                width: isNotSmallScreen ? 100 : 200,
                valueGetter: (_, row) =>
                    OrganizationMetadataFileType[
                        row.configuration.metaDataFileType
                    ],
            },
            {
                headerName: "Extension",
                field: "lastName",
                width: isNotSmallScreen ? 100 : 200,
                valueGetter: (_, row) =>
                    OrganizationMetadataFileTypeExtension[
                        row.configuration.metaDataFileTypeExtension
                    ],
            },
            {
                headerName: "Last Updated On",
                field: "agentEmail",
                width: isNotSmallScreen ? 100 : 200,
                valueGetter: (_, row) =>
                    standardDateFormat(parseFromISO(row.modifiedOn, true)),
            },
        ],
        [isNotSmallScreen, setActiveStep, setSelectedDatasource],
    );

    useEffect(() => {
        datasourcesDgStore.setColumns(columns);
        loadDataSources();
    }, [columns, datasourcesDgStore, loadDataSources]);

    return (
        <Grid
            container
            sx={{ padding: "40px", minHeight: "100%", maxWidth: "1536px" }}
            direction="column"
            flexWrap="nowrap"
            rowSpacing={3}
        >
            {/* Title, Search, and Add */}
            <Grid item container direction="row" justifyContent="space-between">
                {/* Title */}
                <Grid item xs={4}>
                    <Typography
                        sx={{
                            fontSize: "24px",
                            fontWeight: "600",
                            color: theme.palette.gray[700],
                        }}
                    >
                        Data Sources
                    </Typography>
                </Grid>

                {/* Search and Add Header */}
                <Grid
                    container
                    item
                    direction="row"
                    xs={6}
                    spacing={2}
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <Grid item>
                        <AcxMainTextField
                            key="datasources-search"
                            id="datasources-search"
                            value=""
                            placeholderText="Search Data Sources..."
                            onChange={() => {}}
                            containerStyle={{ minWidth: "225px" }}
                        />
                    </Grid>
                    <Grid item>
                        <AcxButton
                            color="white"
                            onClick={() => setShowAddDataSourceModal(true)}
                        >
                            + Datasource
                        </AcxButton>
                    </Grid>
                </Grid>
            </Grid>

            {/* Stepper Navigation for Configuration */}
            {selectedDatasource && (
                <Grid
                    item
                    xs={12}
                    sx={{
                        justifyContent: "center",
                        display: "flex",
                        maxHeight: "38px",
                    }}
                >
                    <Stepper nonLinear activeStep={activeStep}>
                        {steps.map((label, index) => (
                            <StyledStep
                                key={label}
                                className={clsx({
                                    active: activeStep === index,
                                    inactive: activeStep !== index,
                                    first: index === 0,
                                    last: index === steps.length - 1,
                                })}
                            >
                                <Button
                                    onClick={() => {
                                        if (
                                            (activeStep === 2 &&
                                                checkIfSourceFieldsAreValid()) ||
                                            index <= 2
                                        ) {
                                            setActiveStep(index);
                                        }
                                    }}
                                >
                                    {label}
                                </Button>
                            </StyledStep>
                        ))}
                    </Stepper>
                </Grid>
            )}

            {/* Datagrid of datasources */}
            {activeStep === 0 && (
                <Grid item height="80vh" sx={{ width: "100%" }}>
                    <AcxDataGrid dataGridStore={datasourcesDgStore} />
                </Grid>
            )}

            {/* Setup Page */}
            {activeStep === 1 && (
                <Grid container item xs={4}>
                    <DataSourceSetup />
                </Grid>
            )}

            {/* Source Configuration Page */}
            {activeStep === 2 && (
                <Grid item xs={4}>
                    <DataSourceConfiguration
                        store={organizationDataSourcesStore}
                    />
                </Grid>
            )}

            {/* Target Mapping Page */}

            {/* Progression and Cancel Buttons */}
            {activeStep !== 0 && activeStep !== 5 && (
                <Grid
                    container
                    item
                    xs={0.5}
                    flexDirection="row"
                    justifyContent="flex-end"
                    gap={2}
                >
                    <AcxButton
                        color="white"
                        onClick={() => setActiveStep(activeStep - 1)}
                    >
                        {activeStep === 1 ? "Cancel" : "Back"}
                    </AcxButton>
                    <AcxButton
                        color="primary"
                        onClick={() => {
                            if (
                                (activeStep === 2 &&
                                    checkIfSourceFieldsAreValid()) ||
                                activeStep !== 2
                            ) {
                                setActiveStep(activeStep + 1);
                            }
                        }}
                        endIcon={<ArrowRightAltIcon />}
                    >
                        Next
                    </AcxButton>
                </Grid>
            )}

            {/* Add Data Source Modal */}
            <AcxDialog
                title={"New Data Source Configuration"}
                text={"Configure New Metadata Source"}
                isOpen={showAddDataSourceModal}
                onClose={() => setShowAddDataSourceModal(false)}
                contentWidth="400px"
                dialogContentChildren={
                    <Grid container flexDirection={"column"}>
                        <Grid item>
                            <AcxSelect
                                inputLabel="Select Metadata Source"
                                id={"select-metadata-source"}
                                options={metadataSourceOptions}
                                labelField="label"
                                valueField="value"
                            />
                        </Grid>
                    </Grid>
                }
            >
                <AcxButton
                    color="primary"
                    disabled={false}
                    onClick={() => {
                        setActiveStep(1);
                        setShowAddDataSourceModal(false);
                    }}
                >
                    Continue
                </AcxButton>
            </AcxDialog>
        </Grid>
    );
});

export default OrganizationDataSources;
