import React, { useMemo } from "react";
import { OrganizationDataSourcesStore } from "../OrganizationDataSourcesStore";
import DataSourceContainer from "../DataSourceContainer";
import ConcatField from "./ConcatField";
import AddIcon from "@mui/icons-material/Add";
import AcxButton from "components/UI/AcxButton";
import { v4 as uuidv4 } from "uuid";
import theme from "Theme/AppTheme";
import { observer } from "mobx-react";
import { Box } from "@mui/material";

type ConcatFieldsProps = {
    store: OrganizationDataSourcesStore;
};

const ConcatFields: React.FC<ConcatFieldsProps> = observer(({ store }) => {
    const { concatFields, setConcatFields, sourceFields } = store;

    const updateConcatFields = (
        id: string,
        key: string,
        value: string | string[],
    ) => {
        setConcatFields(
            concatFields.map((field) => {
                if (field.id === id) {
                    return {
                        ...field,
                        [key]: value,
                    };
                }
                return field;
            }),
        );
    };

    const addNewConcatField = () => {
        setConcatFields([
            ...concatFields,
            {
                id: uuidv4(),
                newName: "",
                fields: [],
                separator: "",
            },
        ]);
    };

    const removeConcatField = (id: string) => {
        setConcatFields(concatFields.filter((field) => field.id !== id));
    };

    const sourceFieldOptions = useMemo(
        () =>
            sourceFields.map((field) => ({
                label: field.alias,
                value: field.alias,
            })),
        [sourceFields],
    );

    return (
        <DataSourceContainer
            heading="Concatenate Fields"
            subHeading="Combine multiple fields into a single output"
        >
            <Box
                sx={{
                    gap: theme.spacing(2),
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                {concatFields.map(({ id, newName, fields, separator }) => {
                    return (
                        <ConcatField
                            key={id}
                            id={id}
                            newName={newName}
                            fields={fields}
                            sourceFieldOptions={sourceFieldOptions}
                            separator={separator}
                            removeConcatField={() => removeConcatField(id)}
                            updateConcatFields={(key, value) =>
                                updateConcatFields(id, key, value)
                            }
                        />
                    );
                })}
            </Box>
            <AcxButton
                startIcon={<AddIcon />}
                onClick={addNewConcatField}
                color="white"
                sx={{
                    color: theme.palette.primary.main,
                    marginTop: theme.spacing(2),
                }}
            >
                Field
            </AcxButton>
        </DataSourceContainer>
    );
});

export default ConcatFields;
