import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ConversationsStore, {
    SidePanelType,
} from "components/Conversations/Stores/ConversationsStore";
import ConfirmationDialog from "components/UI/AcxConfirmationDialog";
import AcxSelect from "components/UI/Select/BaseSelectComponents/AcxSelect";
import { observer } from "mobx-react";
import React, { useMemo } from "react";
import { useStore } from "utils/useStore";
import ConversationLabelingStore, {
    loadConvoModules,
    loadDataLabelModules,
} from "./FocusedConversation/components/convoSidePanel/store/ConversationLabelingStore";

const useStyles = makeStyles((theme: Theme) => ({
    labelConversationDialogTitle: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "20px",
        color: theme.palette.blackFont.main,
    },
    labelConversationDialogHierachy: {
        color: theme.palette.blackFont.main,
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "20px",
    },
    labelConversationDialogContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "12px",
        alignSelf: "stretch",
    },
}));

const LabelConversationDialog = observer(() => {
    const classes = useStyles();
    const conversationStore = useStore(ConversationsStore);
    const dataLabelStore = useStore(ConversationLabelingStore);

    const initDatalabelingDrawer = () => {
        if (dataLabelStore.selectedLicensedModuleId) {
            dataLabelStore.selectedConversationModuleId =
                dataLabelStore.convoModuleInfo.find(
                    (cmi) =>
                        cmi.licensedModuleId ===
                        dataLabelStore.selectedLicensedModuleId,
                )?.conversationModuleId;

            conversationStore.setSidePanel(SidePanelType.DataLabeling);
            dataLabelStore.getLicensedModuleAndInitUiModel(
                dataLabelStore.selectedLicensedModuleId,
            );
        }
    };

    const options = useMemo(() => {
        const conversationLabelToOptionMap = {};
        dataLabelStore.conversationLicensedModules.forEach((module) => {
            const moduleCategory = module.moduleCategory.name;
            if (!conversationLabelToOptionMap[moduleCategory]) {
                conversationLabelToOptionMap[moduleCategory] = [
                    { name: module.name, id: module.id },
                ];
            } else {
                conversationLabelToOptionMap[moduleCategory].push({
                    name: module.name,
                    id: module.id,
                });
            }
        });

        const categories = Object.keys(conversationLabelToOptionMap);

        if (categories.length === 0) {
            return [];
        } else if (categories.length === 1) {
            return conversationLabelToOptionMap[categories[0]];
        } else {
            return categories.map((category) => {
                return {
                    label: category,
                    options: conversationLabelToOptionMap[category],
                };
            });
        }
    }, [dataLabelStore.conversationLicensedModules]);

    return (
        <ConfirmationDialog
            title={"Label this conversation"}
            isOpen={dataLabelStore.labelConversationDialogOpen}
            onClose={dataLabelStore.toggleLabelConversationDialog}
            onConfirm={() => {
                dataLabelStore.toggleLabelConversationDialog();
                initDatalabelingDrawer();
            }}
            subTitle="Select a module to start labeling this conversation for data insights."
            content={
                <div className={classes.labelConversationDialogContent}>
                    <AcxSelect
                        options={options}
                        id="label-conversation-select-module"
                        inputLabel="Available Modules"
                        fullWidth
                        isLoading={dataLabelStore.getTaskLoadingV2(
                            loadDataLabelModules,
                        )}
                        valueField="id"
                        labelField="name"
                        onChange={(e) => {
                            const selectedId = e.id;
                            dataLabelStore.selectedLicensedModuleId =
                                selectedId;
                        }}
                    />
                </div>
            }
            isLoading={dataLabelStore.getTaskLoadingV2(loadConvoModules)}
            confirmButtonDisabled={!dataLabelStore.selectedLicensedModuleId}
        />
    );
});

export default LabelConversationDialog;
