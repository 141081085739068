import { observer } from "mobx-react";
import { IApplicationFilterComponent } from "../Filters/IApplicationFilterComponent";
import React from "react";
import { Grid } from "@mui/material";
import theme from "Theme/AppTheme";
import AcxSelectSingle from "components/UI/Select/BaseSelectComponents/AcxSelectSingle";
import { useStore } from "utils/useStore";
import { ApplicationFilterValuesStore } from "stores/ApplicationFilters/ApplicationFilterValuesStore";
import { AuthStore } from "stores/AuthStore";
import AcxSelectMultiAsync from "components/UI/Select/BaseSelectComponents/AcxSelectMultiAsync";

export const PrimaryTopicsFilter = observer(function (
    props: IApplicationFilterComponent,
) {
    const valuesStore = useStore(ApplicationFilterValuesStore);
    const authStore = useStore(AuthStore);

    if (!authStore.canUserView("Topics")) return null;

    return (
        <Grid id={props.id} container item direction="column">
            <Grid item>
                <AcxSelectSingle
                    id={"application-filters-primary-topics-contains"}
                    options={[
                        { label: "is one of", value: true },
                        { label: "is not one of", value: false },
                    ]}
                    labelField="label"
                    valueField="value"
                    defaultValue={{
                        label: props.store.containsPrimaryTopicsString,
                        value: props.store.containsPrimaryTopics,
                    }}
                    customStyle={{
                        container: (provided, state) => ({
                            ...provided,
                            backgroundColor: theme.palette.lightgray.main,
                            // cant find a better way to adjust this width
                            width: props.store.containsPrimaryTopics
                                ? "100px"
                                : "125px",
                        }),
                    }}
                    onChange={(item) => {
                        props.store.setContainsPrimaryTopics(item.value);
                    }}
                />
            </Grid>
            <AcxSelectMultiAsync
                id="application-filters-primary-topics"
                loadOptions={async (searchValue) => {
                    return await valuesStore.searchPrimaryTopic(searchValue);
                }}
                defaultValue={props.store.primaryTopics}
                value={props.store.primaryTopics}
                onChange={(options) => {
                    props.store.setPrimaryTopics(!!options ? options : []);
                }}
                valueField="topicNumber"
                labelField="topicLabel"
                isClearable={true}
                noOptionMessage={"Please enter topic name."}
            />
        </Grid>
    );
});
